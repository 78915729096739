import React from 'react';
import {
  QueryAction, Resource,
  ColumnSet, MutationAction, RefField,
} from 'ants/resource';
import * as Yup from 'yup';
import { arrayAsObject, objectAsArray, stripKeys } from 'lib/utils';
import { getAccountKey } from 'core/localStore';
import {
  TournamentTeamReadQuery,
  TeamFromTournamentTeamQuery,
  TournamentTeamUpdatePlayersMutation,
} from './query';
import { TournamentTeamListQuery } from '../tournament/query';
import { TournamentTeamImageView } from './ResourceImage';

function ValidateSquad() {
  const ValidateSquadItem = (value) => {
    const squad = objectAsArray(value);
    return squad.filter((x) => !x).length === 0;
  };

  return Yup.mixed()
    .test(
      'error',
      ValidateSquadItem,
    );
}

export const TournamentTeamResource = Resource({
  resourceId: 'TournamentTeam',
  name: 'Tournament Team',
  storeId: 'TournamentTeam',
  pageKey: 'sports.cricket.seasonTeam',

  columnSets: [
    ColumnSet({
      name: 'Squad',
      shape: Yup.object().shape({
        squad: ValidateSquad(),
      }),
      historyKey: 'CricketTournamentTeamSquad',
    }),
    ColumnSet({
      name: 'LegacyData',
      shape: Yup.object().shape({
        key: Yup.string().nullable().default(),
      }),
      viewKey: 'legacy_data',
      historyKey: 'TournamentTeamLegacyData',
    }),
    ColumnSet({
      name: 'Team',
      shape: Yup.object().shape({
        captain: RefField(),
        name: Yup.string().nullable().default(),
        short_name: Yup.string().nullable().default(),
      }),
      referenceFields: ['captain'],
      historyKey: 'TournamentTeamTeam',
    }),
  ],

  listPrimaryActions: [
    { action: 'update', name: 'Update Players' },
  ],

  imageRender: (item) => (<TournamentTeamImageView item={item} />),

  queries: {
    read: QueryAction(
      {
        query: TournamentTeamReadQuery,
        resourcePath: 'item.resource.hashkey',
        responsePath: 'sports_cricket_tournament_team_read',
        resourceNamePath: 'item.team.name',
      },
    ),
    from_team: QueryAction(
      {
        query: TeamFromTournamentTeamQuery,
        resourcePath: 'item.resource.hashkey',
        responsePath: 'sports_cricket_tournament_team_from_team',
        resourceNamePath: 'item.team.name',
      },
    ),
    tournamentTeamList: QueryAction({
      query: TournamentTeamListQuery,
      resourceNamePath: 'item.tournament.internal_name',
      resourcePath: 'item.resource.hashkey',
      responsePath: 'sports_tournament_read',
    }),
  },

  mutations: {
    update: MutationAction(
      {
        mutation: TournamentTeamUpdatePlayersMutation,
        cs: ['squad', 'legacy_data', 'team'],
        responsePath: 'sports_cricket_tournament_team_update_players',
        prepareForEdit(self, resource, data) {
          const resp = self.defaultPrepareForEdit(self, resource, data);
          resp.squad.squad = arrayAsObject(resp.squad.squad) ?? {};
          return resp;
        },
        prepareForSave(self, resource, data) {
          const resp = self.defaultPrepareForSave(self, resource, data);
          let postVariables;
          const exisitingPlayers = new Set();
          if (resp.squad && resp.squad) {
            resp.squad.squad = objectAsArray(resp.squad.squad);
            postVariables = {
              resource: {
              // eslint-disable-next-line no-underscore-dangle
                _hashkey: data._hashkey,
              },
              squad: {
                squad: resp.squad.squad
                  ? objectAsArray(resp.squad.squad).filter(
                    (player) => {
                      if (player && player.name && !exisitingPlayers.has(player.player.hashkey)) {
                        exisitingPlayers.add(player.hashkey);
                        return true;
                      }
                      return false;
                    },
                  ).map((squad) => {
                    // eslint-disable-next-line camelcase
                    const { recent_matches, ...remainingSquadDetails } = squad;
                    return {
                      ...remainingSquadDetails,
                      player: {
                        account: (squad && squad.player && squad.player.account) || null,
                        sport: (squad && squad.player && squad.player.sport) || null,
                        key: (squad && squad.player && squad.player.key) || null,
                        _hashkey: (squad && squad.player && squad.player.hashkey) || null,
                      },
                      batting_style: squad?.batting_style || null,
                      playing_role: squad?.playing_role || squad?.playing_role,
                      bowling_style: objectAsArray(squad?.bowling_style) || [],
                      frequent_bowling_deliveries: objectAsArray(
                        squad?.frequent_bowling_deliveries,
                      ) || [],
                      alltime_roles: objectAsArray(stripKeys(squad.alltime_roles)),
                      replaced_by: squad && squad.replaced_by && squad.replaced_by.hashkey ? {
                        _hashkey: squad.replaced_by.hashkey,
                        account: squad.replaced_by.account || null,
                        sport: squad.replaced_by.sport || null,
                        key: squad.replaced_by.key || null,
                      } : null,
                      region: squad?.region ? {
                        _hashkey: squad?.region?.hashkey,
                        account: squad?.region?.account || getAccountKey(),
                        key: squad?.region?.key,
                      } : null,
                    };
                  })
                  : [],
              },
            };
          }
          return postVariables ?? resp;
        },
      },
    ),
  },
});
