import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListController } from 'core/ListController';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import { ResourceTypeBoundary } from 'ants/ResourceBoundary';
import { Link } from 'react-router-dom';
import { ErrorBoundary } from 'core/ErrorBoundary';

export function ResourceListItemActions({
  resourceDef,
  urlParams,
  resource,
  resourceKey,
  buttonClasses = 'plain bordered small',
}) {
  return (
    <div>
      {resourceDef?.listPrimaryActions && resourceDef.listPrimaryActions.map((listAction) => (
        <Link
          key={listAction.action}
          className={buttonClasses}
          to={
            resourceDef.urlFor(
              resourceDef,
              {
                page: listAction.page ?? listAction.action,
                resource,
                resourceKey,
                urlParams: urlParams || {},
              },
            )
          }
        >
          {listAction.name ?? resourceDef.mutations[listAction.action].title }
        </Link>
      ))}
    </div>
  );
}

ResourceListItemActions.propTypes = {
  resourceDef: PropTypes.object,
  urlParams: PropTypes.object,
  resourceKey: PropTypes.string,
  resource: PropTypes.object,
  buttonClasses: PropTypes.object,
};

const ResourceListControllerWrapper = styled.div`
  .plain {
    margin-right: ${asRem(8)};
  }
`;

export function ResourceListController({
  resourceDef,
  title,
  tagFilter,
  resourceID,
  action,
  actionPath,
  variables = {},
  renderRowHead,
  renderRows,
  selectedTableContent,
  enableFilters,
  internalFilters,
  addedTableContent,
  renderActions,
  useSearch = true,
  showdisabled = false,
  warningmsg,
  showList = true,
  createNewResource = true,
  renderAdditionalFilters = null,
  onRemoveFilters = null,
  renderAdditionalFilterTags = null,
  showActions = true,
  ignoreLayoutAction,
  ...listParams
}) {
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (!ignoreLayoutAction) {
      appContext.setLayoutWidth(AppLayoutWidth.One);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreLayoutAction]);

  const resolvedVariables = {
    search: '',
    ...variables,
  };

  return (
    <ResourceListControllerWrapper>
      <ResourceTypeBoundary
        resourceDef={resourceDef}
        action={action}
      >
        <ErrorBoundary>
          <ListController
            query={action.query}
            resourceDef={resourceDef}
            tagFilter={tagFilter}
            selectedTableContent={selectedTableContent}
            enableFilters={enableFilters}
            internalFilters={internalFilters}
            addedTableContent={addedTableContent}
            resourceID={resourceID}
            variables={resolvedVariables}
            responseKey={action.responsePath}
            itemsKey={action.itemsPath}
            actionName={`Create new ${resourceDef.name}`}
            title={title || resourceDef.name}
            actionPath={createNewResource ? actionPath ?? resourceDef.pageFor(resourceDef, 'create')?.meta.url : null}
            renderRowHead={renderRowHead}
            renderSelectedItemActions={() => (
              <div className="actions-wrapper">
                <button type="button" className="plain">Delete</button>
              </div>
            )}
            renderRows={renderRows}
            useSearch={useSearch}
            showdisabled={showdisabled}
            warningmsg={warningmsg}
            showList={showList}
            showTotal
            renderAdditionalFilters={renderAdditionalFilters}
            onRemoveFilters={onRemoveFilters}
            renderAdditionalFilterTags={renderAdditionalFilterTags}
            showActions={showActions}
            renderActions={renderActions}
            {...listParams}
          />
        </ErrorBoundary>
      </ResourceTypeBoundary>
    </ResourceListControllerWrapper>
  );
}

ResourceListController.propTypes = {
  resourceDef: PropTypes.object,
  action: PropTypes.object,
  title: PropTypes.string,
  tagFilter: PropTypes.string,
  resourceID: PropTypes.string,
  variables: PropTypes.object,
  renderRowHead: PropTypes.func,
  renderRows: PropTypes.func,
  enableFilters: PropTypes.bool,
  internalFilters: PropTypes.array,
  selectedTableContent: PropTypes.any,
  addedTableContent: PropTypes.array,
  actionPath: PropTypes.string,
  useSearch: PropTypes.bool,
  showdisabled: PropTypes.bool,
  warningmsg: PropTypes.string,
  showList: PropTypes.bool,
  createNewResource: PropTypes.bool,
  renderAdditionalFilters: PropTypes.func,
  onRemoveFilters: PropTypes.func,
  renderAdditionalFilterTags: PropTypes.func,
  showActions: PropTypes.bool,
  ignoreLayoutAction: PropTypes.bool,
  renderActions: PropTypes.func,
};
