import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as Select from '@radix-ui/react-select';
import { ReactComponent as DropdownIcon } from 'assets/icons/icon-dropdown.svg';
import { asRem } from 'lib/css';

const StyledSelectTrigger = styled(Select.Trigger)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: var(--color-border-2);
  color: var(--color-text-1);
  box-shadow: 0 2px 10px var(--black-a7);
  :hover {
    background-color: var(--mauve-3);
  }
  &[data-placeholder] {
    color: var(--violet-9);
  }
  .SelectIcon {
    color: Var(--violet-11);
  }
`;

const StyledSelectContent = styled(Select.Content)`
  overflow: hidden;
  background-color: var(--color-border-2);
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 100000;

  .SelectViewport {
    padding: 5px;
  }


  .SelectLabel {
    font-size: 12px;
    line-height: 25px;
    color: var(--color-label);
  }

  .SelectSeparator {
    height: 1px;
    background-color: var(--color-table-border);
    margin: 5px;
  }

  .SelectScrollButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    color: var(--violet-11);
    cursor: default;
  }
`;

const StyledSelectItem = styled(Select.Item)`
  line-height: 1.2;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: ${asRem(40)};
  width: 100%;
  color: var(--color-text-1);
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  &[data-disabled] {
    color: var(--color-disabled-text);
    pointer-events: none;
  }
  &[data-highlighted] {
    outline: none;
    color: var(--color-highlight-2);
  }

  .SelectItemIndicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

`;

const StyledSelect = styled(Select.Root)`
`;

const SelectItemProps = PropTypes.shape({
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isSeparator: PropTypes.bool,
  isLabel: PropTypes.bool,
});

export function SelectV2Item({ item, fallbackKey }) {
  if (item.isSeparator) {
    return <Select.Separator key={item.value || fallbackKey} className="SelectSeparator" />;
  }
  if (item.isLabel) {
    return <Select.Label key={item.value || fallbackKey} className="SelectLabel">{item.name}</Select.Label>;
  }

  return (
    <SelectItem
      key={item.value}
      value={item.value}
      disabled={item.disabled}
    >
      {item.name}
    </SelectItem>
  );
}

SelectV2Item.propTypes = {
  item: SelectItemProps,
  fallbackKey: PropTypes.string,
};

export function SelectV2({
  items,
  groupedItems,
  placeholder,
  ...props
}) {
  return (
    <StyledSelect {...props}>
      <StyledSelectTrigger className="SelectTrigger" aria-label="Select Items">
        <Select.Value placeholder={placeholder || 'Select'} />
        <Select.Icon className="SelectIcon">
          <DropdownIcon />
        </Select.Icon>
      </StyledSelectTrigger>
      <Select.Portal>
        <StyledSelectContent className="SelectContent">
          <Select.ScrollUpButton className="SelectScrollButton">
            <DropdownIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className="SelectViewport">
            {items && items.map((item, index) => (
              <SelectV2Item item={item} fallbackKey={item.value || index} />
            ))}
            {groupedItems && groupedItems.map((gitems, gindex) => (
              <Select.Group key={gitems.key || gindex}>
                {gitems.label && <Select.Label className="SelectLabel">{gitems.label}</Select.Label>}
                {gitems.items.map((item, index) => (
                  <SelectV2Item item={item} fallbackKey={item.value || index} />
                ))}
                {gindex < groupedItems.length - 1 && <Select.Separator key={`${gitems.key || gindex}-sep`} className="SelectSeparator" />}
              </Select.Group>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton">
            <DropdownIcon />
          </Select.ScrollDownButton>
        </StyledSelectContent>
      </Select.Portal>
    </StyledSelect>
  );
}

SelectV2.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(SelectItemProps),
  groupedItems: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    items: PropTypes.arrayOf(SelectItemProps),
  })),
};

const SelectItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => {
  const classNames = `SelectItem ${className}`;
  return (
    <StyledSelectItem className={classNames} {...props} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
      <Select.ItemIndicator className="SelectItemIndicator">
        &#x2713;
      </Select.ItemIndicator>
    </StyledSelectItem>
  );
});

SelectItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
