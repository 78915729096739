import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { dataFromPath } from 'lib/utils';
import { FormItem } from 'core/FormFieldItem';
import { Text14Regular } from 'style/typography';
import { StatusError, StatusLoading } from './Status';
import { SelectV2 } from './SelectV2';
import { saveSelectedWebsiteName } from './localStore';
import { UserContext } from './UserContext';

const ResourceSelectWrapper = styled.div`
`;

export function ResourceSelect({
  name,
  resourceDef,
  variables = null,
  queryAction = null,
  onSelect = null,
  ...formOptions
}) {
  const resolvedQuery = queryAction ?? resourceDef.queries.search;
  const resolvedVariables = variables ?? {
    search: '',
  };

  const { loading, error, data } = useQuery(
    resolvedQuery.query,
    { variables: resolvedVariables },
  );

  let options = null;
  const keyMap = {};
  const resp = dataFromPath(data, resolvedQuery.responsePath);
  if (data && resp) {
    options = [];
    dataFromPath(resp, resolvedQuery.itemsPath).forEach((item) => {
      const key = dataFromPath(item, resolvedQuery.resourcePath);
      options.push({
        key,
        name: dataFromPath(item, resolvedQuery.resourceNamePath),
      });
      keyMap[key] = item;
    });
  }

  const moreOptions = {};
  if (onSelect) {
    moreOptions.onChange = (event) => {
      const item = keyMap[event.target.value] ?? null;
      onSelect(item);
    };
  }

  return (
    <ResourceSelectWrapper>
      {loading && (<StatusLoading message="loading..." />)}
      {error && (<StatusError error={error} />)}
      {options && (
        <FormItem
          {...formOptions}
          {...moreOptions}
          type="select"
          name={name}
          options={options}
        />
      )}

    </ResourceSelectWrapper>
  );
}

ResourceSelect.propTypes = {
  name: PropTypes.string,
  resourceDef: PropTypes.object,
  queryAction: PropTypes.object,
  variables: PropTypes.object,
  onSelect: PropTypes.func,
  ...FormItem.propTypes,
};

const ResourceSelectBasicWrapper = styled.div`
`;

export function ResourceSelectBasic({
  resourceDef,
  // value = null,
  variables = null,
  queryAction = null,
  onSelect = null,
  autoSelectFirst = false,
  defaultSelector = null,
  onItems = null,
  renderIcon,
  renderDefaultContent,
}) {
  const userContext = useContext(UserContext);
  const [value, setValue] = React.useState(null);
  const resolvedQuery = queryAction ?? resourceDef.queries.search;
  const resolvedVariables = variables ?? {
    search: '',
  };

  const { loading, error, data } = useQuery(
    resolvedQuery.query,
    { variables: resolvedVariables },
  );

  let options = null;
  const keyMap = {};
  const resp = dataFromPath(data, resolvedQuery.responsePath);
  if (data && resp) {
    options = [];
    dataFromPath(resp, resolvedQuery.itemsPath).forEach((item) => {
      const key = dataFromPath(item, resolvedQuery.resourcePath);
      options.push({
        value: key,
        name: dataFromPath(item, resolvedQuery.resourceNamePath),
      });
      keyMap[key] = item;
    });
    if (onItems) {
      onItems(options, resp);
    }
  }

  // const moreOptions = {};
  // if (onSelect) {
  //   moreOptions.onChange = (event) => {
  //     const item = keyMap[event.target.value] ?? null;
  //     onSelect(item);
  //   };
  // }

  const setSelectedValue = (v) => {
    setValue(v);
    if (onSelect) {
      const item = keyMap[v] ?? null;
      onSelect({ value: v, item });
    }
  };

  let defaultValue = null;
  if (options && defaultSelector) {
    defaultValue = defaultSelector(options, resp);
  } else if (autoSelectFirst && options) {
    defaultValue = options?.[0]?.value;
  }

  if (!value && defaultValue && defaultValue !== value) {
    setSelectedValue(defaultValue);
  }

  useEffect(() => {
    if (autoSelectFirst && options && !value) {
      userContext.setSelectedWebsite(options?.[0]?.value?.resource?.hashkey);
      saveSelectedWebsiteName(options?.[0]?.value?.name?.name);
    }
  }, [autoSelectFirst]);

  return (
    <ResourceSelectBasicWrapper>
      {loading && (<StatusLoading message="loading..." />)}
      {error && (<StatusError error={error} />)}
      {options?.length > 0 ? (
        <div className="wrapper-container">
          {renderIcon && renderIcon()}
          {options.length > 1 ? (
            <SelectV2
              items={options}
              defaultValue={defaultValue}
              value={value}
              onValueChange={setSelectedValue}
            />
          ) : (<Text14Regular>{options?.[0]?.name}</Text14Regular>)}
        </div>

      ) : (
        <div>
          {renderDefaultContent && renderDefaultContent()}
        </div>
      )}

    </ResourceSelectBasicWrapper>
  );
}

ResourceSelectBasic.propTypes = {
  resourceDef: PropTypes.object,
  // value: PropTypes.any,
  queryAction: PropTypes.object,
  variables: PropTypes.object,
  onSelect: PropTypes.func,
  onItems: PropTypes.func,
  defaultSelector: PropTypes.func,
  autoSelectFirst: PropTypes.bool,
  renderIcon: PropTypes.func,
  renderDefaultContent: PropTypes.func,
};
