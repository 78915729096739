import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { BootQuery } from 'core/query';
import { AppScreenError, AppScreenLoading } from './Screen';

export const AppLayoutWidth = {
  Fullwidth: 'Fullwidth', // For example Revision Page
  One: 'One', // List pages
  Two: 'Two', // Content Edit pages
  Three: 'Three', // Dashboard page
  Four: 'Four', // Create resource pages
  Five: 'Five', // setup screens
  Six: 'Six', // account settings screens
  Seven: 'Seven',
  Eight: 'Eight',
  Nine: 'Nine', // Preview screens
  Ten: 'Ten',
  Pricing: 'Pricing',
  Import: 'Import',
  Schedule: 'Schedule',
  V2: 'V2',
};

export const MenuLayoutWidth = {
  CmsMenuFullView: 'CmsMenuFullView',
  CmsMenuCollapsedView: 'CmsMenuCollapsedView',
};

const defaultContext = {
  theme: 'auto',
  boot: null,
  showPrimaryMenu: true,
  showSecondaryMenu: false,
  secondaryMenuItems: [],
  viewState: {
    top: true,
    right: false,
    bottom: true,
    left: false,
    doubelTop: false,
    isFullScreen: false,
    layoutWidth: null,
  },
  menuLayout: null,
  listViewOpenState: null,
};

export const AppContext = React.createContext({ ...defaultContext });
export const AppContextConsumer = AppContext.Consumer;

export function AppContextProvider({ value, children }) {
  const [appContext, setAppContext] = useState({
    setTheme(theme) {
      setAppContext((state) => ({
        ...state,
        theme,
      }));
    },
    setBootData: (boot) => {
      setAppContext((state) => ({
        ...state,
        boot,
      }));
    },
    setViewStateValue: (key, stateValue) => {
      setAppContext((state) => ({
        ...state,
        viewState: {
          ...state.viewState,
          [key]: stateValue,
        },
      }));
    },
    toggleTopMenu: ({ primary, secondary }) => {
      setAppContext((state) => ({
        ...state,
        showPrimaryMenu: primary,
        showSecondaryMenu: secondary,
      }));
    },
    setSecondaryMenu: (name, items) => {
      setAppContext((state) => ({
        ...state,
        secondaryMenuName: name,
        secondaryMenuItems: items,
      }));
    },
    setLayoutWidth(newState) {
      setAppContext((state) => {
        const updatedState = {
          ...state,
        };

        if (updatedState.viewState.layoutWidth !== newState) {
          updatedState.viewState = {
            ...updatedState.viewState,
            layoutWidth: newState,
          };
        }
        return updatedState;
      });
    },
    setMenuLayout(menuLayout) {
      setAppContext((state) => ({
        ...state,
        menuLayout,
      }));
    },
    setListViewOpenState(listViewOpenState) {
      setAppContext((state) => ({
        ...state,
        listViewOpenState,
      }));
    },
    ...defaultContext,
    ...value,
  });

  const { loading, error, data } = useQuery(BootQuery);

  if (loading && !data) return (<AppScreenLoading />);
  if (error && !data) return (<AppScreenError error={error} />);

  if (data && !appContext.boot) {
    appContext.setBootData(data.ants_boot);
  }

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.element,
};
