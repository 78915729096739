import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const TournamentCreateHintQuery = gql`
query TournamentCreateHintQuery($name: String!) {
  sports_tournament_create_hint(name: $name) {
    name
    short_name
    internal_name
    similar_items {
      resource {
        account
        sport
        association
        competition
        key
        hashkey: _hashkey
      }
      tournament {
        name
        internal_name
        short_name
        start_date
      }
    }
    key_suggestions
  }
}
`;

export const TournamentCreateMutation = gql`
mutation TournamentCreateMutation(
  $association: AssociationInput!,
  $competition: CompetitionInput!,
  $tournament: TournamentTournamentInput!,
  $bio: TournamentBioInput!,
  $cricket: CricketTournamentCricketInput!,
  $result: TournamentResultInput,
  $status: TournamentStatusInput!,
  $key: String!) {
  sports_tournament_create(association: $association, competition: $competition, tournament: $tournament,
  bio: $bio, cricket: $cricket, result: $result, key: $key, status: $status) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
    name
    key_suggestions
  }
}
`;

export const TournamentAllTeamSearch = gql`
query TournamentAllTeamSearch($search: String!,
  $tournament: TournamentInput!,
  $page: PagedRequestInput,
) {
  sports_cricket_tournament_team_all_teams_search(search: $search, tournament: $tournament, page: $page) {
    items {
      resource {
        tournament
        team
        hashkey: _hashkey
      }
      team {
        name
        short_name
        region {
          account
          key
          hashkey: _hashkey
        }
        gender
      }
      image {
        image_url
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const TournamentSearchQuery = gql`
query TournamentSearchQuery($search: String!,
  $page: PagedRequestInput,
  $filters: TournamentFiltersInput,
  $liveTournaments: Boolean,
) {
  sports_tournament_search(search: $search, page: $page, filters: $filters, show_live_tournaments_only: $liveTournaments) {
    items {
      resource {
        account
        sport
        association
        competition
        key
        hashkey: _hashkey
      }
      tournament {
        name
        internal_name
        short_name
        gender
        start_date
        related_tournament_key
        tour_key
        enable_stats
        enable_association_stats
        enable_competition_stats
        play_kind
      }
      cricket {
        format
        mg
        v2_resource_key
        v4_resource_key
      }
      cricket_tournament_team {
        teams
      }
      image {
        image_url
      }
      status {
        published
        notes
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
    top_counts {
      genders {
        key
        name
        count
      }
      associations {
        key
        name
        count
      }
      competitions {
        key
        name
        count
      }
      month {
        key
        name
        count
      }
    }
  }
}
`;

export const TournamentTopCountsQuery = gql`
query TournamentTopCounts($search: String!,
  $page: PagedRequestInput,
  $filters: TournamentFiltersInput,
  $liveTournaments: Boolean,
) {
  sports_tournament_search(search: $search, page: $page, filters: $filters, show_live_tournaments_only: $liveTournaments) {
    top_counts {
      genders {
        key
        name
        count
      }
      associations {
        key
        name
        count
      }
      competitions {
        key
        name
        count
      }
      month {
        key
        name
        count
      }
    }
  }
}
`;

export const TournamentTeamNameQuery = gql`
query TournamentTeamNameQuery($resource: TournamentTeamInput!) {
  sports_cricket_tournament_team_name(resource: $resource) {
    name
    short_name
    image_url
    gender
  }
}
`;

export const TournamentReadQuery = gql`
query TournamentReadQuery($resource: TournamentInput!,
  $withHistory: Boolean,
) {
  sports_tournament_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        sport
        association
        competition
        key
        hashkey: _hashkey
      }
      tournament {
        name
        internal_name
        short_name
        gender
        start_date
        related_tournament_key
        tour_key
        enable_stats
        enable_association_stats
        enable_competition_stats
        play_kind
        end_date
        priority
      }
      bio {
        gender
        administrators {
          account
          key
          hashkey: _hashkey
        }
        hosts {
          account
          key
          hashkey: _hashkey
        }
      }
      cricket {
        format
        mg
        v2_resource_key
        v4_resource_key
      }
      cricket_tournament_team {
        teams
      }
      rules {
        overs_per_innings
        drs_per_innings
        balls_per_over
        player_per_team
        bench_per_team
        bowler_per_innings
        batsman_per_innings
        runs_per_no_ball
        runs_per_wide
        tiebreaker
        tiebreaker_type
        max_innings_per_team
        tiebreaker_overs
      }
      result {
        winner
        runner_up
        players_of_the_series
      }
      status {
        published
        notes
      }
      image {
        image_url
      }
      computed {
        start_date
        end_date
        first_match
        start_date_updated_round
        end_date_updated_round
      }
      legacy_data {
        key
      }
      total_matches_count {
        total_matches
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const TournamentUpdateMutation = gql`
mutation TournamentUpdateMutation(
  $resource: TournamentInput!,
  $tournament: TournamentTournamentInput!,
  $bio: TournamentBioInput!,
  $status: TournamentStatusInput!,
  $result: TournamentResultInput!,
  $version: RecordStage) {
  sports_tournament_update(resource: $resource, status: $status,
   tournament: $tournament, bio: $bio, result: $result, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentUpdateStatusMutation = gql`
mutation TournamentUpdateStatusMutation(
  $resource: TournamentInput!,
  $status: TournamentStatusInput!,
  $version: RecordStage) {
  sports_tournament_update_status(resource: $resource, status: $status, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentBasicInfoUpdateMutation = gql`
mutation TournamentBasicInfoUpdateMutation(
  $resource: TournamentInput!,
  $tournament: TournamentTournamentInput!
  $version: RecordStage) {
  sports_tournament_basic_update(resource: $resource, tournament: $tournament, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentTeamUpdateMutation = gql`
mutation TournamentTeamUpdateMutation(
  $resource: TournamentInput!,
  $cricketTournamentTeam: CricketTournamentCricketTournamentTeamInput!
  $version: RecordStage) {
  sports_tournament_update_team(resource: $resource, cricket_tournament_team: $cricketTournamentTeam, version: $version) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentDeleteMutation = gql`
mutation TournamentDeleteMutation(
  $resource: TournamentInput!,
  $name: String!,
  $imageUrl: String) {
  sports_tournament_delete(resource: $resource, name: $name, image_url: $imageUrl) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentSummaryQuery = gql`
query TournamentSummaryQuery($resource: TournamentInput!) {
  sports_tournament_summary(resource: $resource) {
    tournament {
      resource {
        account
        sport
        association
        competition
        key
        hashkey: _hashkey
      }
      tournament {
        name
        internal_name
        short_name
        gender
        start_date
        related_tournament_key
        tour_key
        enable_stats
        enable_association_stats
        enable_competition_stats
        play_kind
        end_date
        priority
      }
      bio {
        gender
        administrators {
          account
          key
          hashkey: _hashkey
        }
        hosts {
          account
          key
          _hashkey
        }
      }
      cricket {
        format
        mg
        v2_resource_key
        v4_resource_key
      }
      cricket_tournament_team {
        teams
      }
      rules {
        overs_per_innings
        drs_per_innings
        balls_per_over
        player_per_team
        bench_per_team
        bowler_per_innings
        batsman_per_innings
        runs_per_no_ball
        runs_per_wide
        tiebreaker
        tiebreaker_type
        max_innings_per_team 
        tiebreaker_overs
      }
      result {
        winner
        runner_up
        players_of_the_series
      }
      status {
        published
        notes
      }
      image {
        image_url
      }
      computed {
        start_date
        end_date
        first_match
        start_date_updated_round
        end_date_updated_round
      }
      legacy_data {
        key
      }
      total_matches_count {
        total_matches
      }
    }
    tournament_rounds {
      resource {
        tournament
        key
        hashkey: _hashkey
      }
      tournament_round {
        name
        index
        groups {
          key
          name
          index
          teams
          matches {
            resource {
              account
              sport
              key
              hashkey: _hashkey
            }
            start_at
            teams {
              team_a {
                tournament
                team
                hashkey: _hashkey
              }
              team_b {
                tournament
                team
                hashkey: _hashkey
              }
            }
            sub_title
            internal_name
            status
            stadium {
              account
              key
              hashkey: _hashkey
            }
            published
            notes
            legacy_key
          }
          legacy_data {
            key
            synced
          }
        }
        type
        enable_points
        group_based_points
      }
      legacy_data {
        key
      }
    }
  }
}
`;

export const TournamentImageUploadMutation = gql`
mutation TournamentImageUploadMutation(
  $resource: TournamentInput!,
  $filename: String!) {
  sports_tournament_image_upload(resource: $resource, filename: $filename) {
    signed_data
    url
    url_suffix
  }
}
`;

export const TournamentNameQuery = gql`
query  TournamentNameQuery($resource: TournamentInput!,) {
  sports_tournament_name(resource: $resource) {
    name
    image_url
  }
}
`;

export const TourCreateMutation = gql`
mutation TourCreateMutation(
  $association: AssociationInput!,
  $tour: CricketTourTourInput!,
  $season_name: String!,
  $mg: CricketMetricGroup!,
  $formats: [CricketMatchFormat!]!,
  $publishTournaments: Boolean,
) {
  sports_cricket_tour_create(
    association: $association,
    tour: $tour,
    season_name: $season_name,
    mg: $mg,
    formats: $formats,
    publish_tournaments: $publishTournaments,
  ) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentCreateQuickMutation = gql`
mutation TournamentCreateQuickMutation(
  $season_name: String!
  $competition: CompetitionInput
  $tournament: TournamentInput
  $auto_include_teams: Boolean
  $auto_include_rounds: Boolean
  $published: Boolean
) {
  sports_tournament_create_quick(
    season_name: $season_name,
    competition: $competition,
    tournament: $tournament,
    auto_include_teams: $auto_include_teams,
    auto_include_rounds: $auto_include_rounds,
    published: $published
  ) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const TournamentTeamRemoveValidation = gql`
 query TournamentTeamRemoveValidation($resource: TournamentTeamInput!) {
  sports_cricket_tournament_team_remove_validation(resource: $resource) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
 }
`;

export const TournamentResultMutation = gql`
 mutation TournamentResultMutation($resource: TournamentInput!, $result: TournamentResultInput!,  $version: RecordStage ){
  sports_tournament_update_result(resource: $resource, result: $result, version: $version) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
 }
`;

export const TournamentRulesMutation = gql`
  mutation TournamentRulesMutation($resource: TournamentInput!, $rules: CricketTournamentRulesInput!, $version: RecordStage) {
      sports_tournament_rules_update(resource: $resource, rules: $rules, version: $version) {
        status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
      }
  }
`;

export const TournamentTeamListQuery = gql`
  query TournamentTeamListQuery($resource: TournamentInput!,
  $withHistory: Boolean) {
    sports_tournament_read(resource: $resource, with_history: $withHistory) 
    {
      item {
        resource {
          account
          sport
          association
          competition
          key
          hashkey: _hashkey
        }
        tournament {
          name
          internal_name
          short_name
          gender
          start_date
          related_tournament_key
          tour_key
          enable_stats
          enable_association_stats
          enable_competition_stats
          play_kind
          end_date
        }
        cricket {
          format
          mg
          v2_resource_key
          v4_resource_key
        }
        cricket_tournament_team {
          teams
        }
      }
    }
  }
`;
