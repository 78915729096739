import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
  // RefField,
} from 'ants/resource';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import { editorMetaCleanUpAndConvert } from 'core/editorMeta';
import { WebsiteImageView } from './ResourceImage';
import {
  WebsiteSearchQuery,
  WebsiteReadQuery,
  WebsiteCreateMutation,
  WebsiteUpdateMutation,
  WebsiteNameQuery,
} from './query';
import { HeadElementUtils } from '../webtree/utils';

export const WebsiteResource = Resource({
  resourceId: 'website',
  resourceKeys: ['website'],
  app: 'cms',
  name: 'Website',
  keyVal: 'Website',
  topicName: 'Website',
  storeId: 'Website',
  sportScope: false,
  columnSets: [
    ColumnSet({
      name: 'Website',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        variables: Yup.array().of(Yup.object().shape({
          key: StringFieldMinMax(1, 120),
          value: StringFieldMinMax(1, 256),
        })).nullable().default([]),
      }),

    }),
    ColumnSet({
      name: 'Props',
      shape: Yup.object().shape({
        props: Yup.mixed().nullable().default(),
      }),

    }),
    // ColumnSet({
    //   name: 'Desc',
    //   shape: Yup.object().shape({
    //     desc: Yup.string().max(1024, 'Too big').nullable().default(''),
    //     image: Yup.string(),
    //   }),
    // }),
  ],
  listPrimaryActions: [
    { action: 'update' },
  ],
  imageRender: (item) => (<WebsiteImageView item={item} />),
  queries: {
    read: QueryAction({
      query: WebsiteReadQuery,
      resourceNamePath: 'item.website.name',
      resourcePath: 'item.resource.hashkey',
    }),
    list: QueryAction({ query: WebsiteSearchQuery, responsePath: 'cms_website_search' }),
    search: QueryAction({
      query: WebsiteSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'name.name',
    }),
    name: QueryAction({ query: WebsiteNameQuery, resourceNamePath: 'cms_website_name.name' }),
  },
  mutations: {
    update: MutationAction({
      mutation: WebsiteUpdateMutation,
      cs: ['website', 'props'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        if (!resp.website.variables) {
          resp.website.variables = [];
        }
        resp.website.variables = arrayAsObject(
          resp.website.variables,
        );
        // console.log('> Prepare for edit', JSON.stringify(resp));
        return resp;
      },
      prepareForSave(self, resource, data) {
        // console.log('Prepare for Save', resource, JSON.stringify(data));
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.website.variables = objectAsArray(resp.website.variables);
        resp.website.options = [];
        if (resp.props.props) {
          const elementKeys = ['head_items', 'body_begin_items', 'body_end_items'];
          elementKeys.forEach((key) => {
            resp.props.props = editorMetaCleanUpAndConvert({
              obj: resp.props.props,
              itemsProp: key,
              cleanUp: data.version,
              cleanUpFunc: (item) => HeadElementUtils.prepareForSave(item, data.version),
            });
          });
          if (resp.props.props.og) {
            resp.props.props.og = editorMetaCleanUpAndConvert({
              obj: resp.props.props.og,
              itemsProp: 'attrs',
              cleanUp: data.version,
            });
            if (resp.props.props.og.attrs === null) {
              resp.props.props.og.attrs = [];
            }
            if (resp.props.props.og.img) {
              resp.props.props.og.img = resp?.props?.props.og?.img.hashkey ? {
                account: resp?.props?.props?.og?.img.account || null,
                key: resp?.props?.props?.og?.img?.key || null,
                _hashkey: resp?.props?.props?.og?.img?.hashkey || null,
              } : null;
            }
          }

          if (resp.props.props.headers) {
            resp.props.props.headers = editorMetaCleanUpAndConvert({
              obj: resp.props.props.headers,
              itemsProp: 'headers',
              cleanUp: data.version,
            });
          }
        }
        return resp;
      },
    }),
    create: MutationAction({ mutation: WebsiteCreateMutation }),
  },
});
