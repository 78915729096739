import { createHashKeyFromParts, createResourceObject } from 'lib/utils';

export function WebtreeFromInt(val, website) {
  if (val === null) {
    return null;
  }
  const key = `${parseInt(val, 16)}`;
  const res = createResourceObject({ key });
  res.website = website;
  return res;
}

export function webtreeHashKey(resource) {
  return createHashKeyFromParts([resource.account, resource.website, resource.key]);
}
