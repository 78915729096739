import React from 'react';
import * as Yup from 'yup';
import {
  ColumnSet, MutationAction, QueryAction, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { CompetitionImageView } from './ResourceImage';
import {
  CompetitionSearchQuery,
  CompetitionReadQuery,
  CompetitionCreateMutation,
  CompetitionUpdateMutation,
  CompetitionUpdateStatusMutation,
  CompetitionNameQuery,
} from './query';

export const CompetitionResource = Resource({
  resourceId: 'competition',
  name: 'Competition',
  storeId: 'Competition',
  pageKey: 'sports.cricket.competition',
  columnSets: [
    ColumnSet({
      name: 'Competition',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        internal_name: StringFieldMinMax(3, 120),
        short_name: StringFieldMinMax(2, 120),
        enable_stats: Yup.boolean().nullable().default(),
        priority: Yup.string().default('Lowest'),
      }),
    }),
    ColumnSet({
      name: 'Bio',
      shape: Yup.object().shape({
        first_edition: Yup.number().integer().nullable().default(0),
        desc: Yup.string().max(1024, 'Too big').nullable().default(''),
        wiki_link: Yup.string().nullable().default(''),
        official_website: Yup.string().nullable().default(''),
        official_logo_url: Yup.string().nullable().default(''),
        discontinued: Yup.boolean().default(false),
      }),
    }),
    ColumnSet({
      name: 'Coverage',
      shape: Yup.object().shape({
        coverage_from: Yup.number().integer().nullable().default(0),
      }),
    }),
    ColumnSet({
      name: 'Series',
      shape: Yup.object().shape({
        one_time: Yup.boolean().default(false),
        interval_unit: Yup.number().integer().nullable().default(),
        interval: Yup.string().nullable().default(''),
      }),
    }),
    ColumnSet({
      name: 'Cricket',
      shape: Yup.object().shape({
        format: StringFieldMinMax(3, 120),
        mg: StringFieldMinMax(1, 120),
      }),
    }),
    ColumnSet({
      name: 'Status',
      shape: Yup.object().shape({
        published: Yup.boolean().default(false),
        notes: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
    }),
  ],
  listPrimaryActions: [
    { action: 'update' },
    { action: 'updateStatus' },
  ],
  imageRender: (item) => (<CompetitionImageView item={item} />),
  queries: {
    read: QueryAction({ query: CompetitionReadQuery, resourceNamePath: 'item.competition.internal_name', resourcePath: 'item.resource.hashkey' }),
    list: QueryAction({ query: CompetitionSearchQuery, responsePath: 'sports_competition_search' }),
    search: QueryAction({
      query: CompetitionSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'competition.internal_name',
      responsePath: 'sports_competition_search',
    }),
    name: QueryAction({ query: CompetitionNameQuery, resourceNamePath: 'sports_competition_name.internal_name' }),
  },
  mutations: {
    update: MutationAction({ mutation: CompetitionUpdateMutation, cs: ['competition', 'bio', 'series', 'cricket', 'coverage', 'status'] }),
    updateStatus: MutationAction({ mutation: CompetitionUpdateStatusMutation, cs: ['status'] }),
    create: MutationAction({ mutation: CompetitionCreateMutation }),
  },
});
