import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { asRem, fwsh } from 'lib/css';

const TabsList = styled(TabsPrimitive.TabsList)`
  display: flex;
  gap: ${asRem(20)};
  border-bottom: 1px solid var(--color-border-2);
  padding: 0 ${asRem(20)};

  &[data-orientation="vertical"] {
    gap: 0;
    flex-direction: column;
    padding: 0;
    border-bottom: none;
  }
`;

export const TabsTrigger = styled(TabsPrimitive.Trigger)`
  padding: ${asRem(8)} ${asRem(14)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${fwsh(400, 14, 20)}
  user-select: none;
  border-radius: 0;
  color: var(--color-text-1);
  
  /* >span {
    padding: 0 ${asRem(4)};
  } */
  
  &[data-orientation="vertical"] {
    padding: ${asRem(14)} 0;
    text-align: left;
    justify-content: start;
    min-width: ${asRem(160)};
    max-width: ${asRem(280)};
    box-shadow: 0 1px 0 0 var(--color-border-2);
    padding: ${asRem(10)} 0;
    >span {
      padding: 0 ${asRem(10)};
    }
  }

  &:hover {
    color: var(--color-button-primary-bg);
  }
  &[data-state="active"] {
    color: var(--color-button-primary-bg);
    box-shadow: 0 1px 0 0 currentColor;
  }
  &:focus {
    /* position: 'relative';
    box-shadow: 0 0 0 2px currentColor; */
  }
`;

const TabsContent = styled(TabsPrimitive.Content)`
  flex-grow: 1;
  &:focus {
  }

  margin: ${asRem(14)};
  &[data-orientation="vertical"] {
    margin: 0;
    padding: ${asRem(14)} 0;
  }
`;

const TabsWrapper = styled(TabsPrimitive.Root)`
  /* display: flex;
  flex-direction: column; */

  &[data-orientation="vertical"] {
    gap: ${asRem(20)};
    display: flex;
    flex-direction: row;
  }
`;

export function Tabs({
  defaultKey,
  items,
  renderTabs,
  renderTabTitle,
  renderContent,
  ...props
}) {
  return (
    <TabsWrapper
      defaultValue={defaultKey}
      {...props}
    >
      <TabsList>
        {renderTabs
          ? renderTabs()
          : items.map((item, index) => (
            <TabsTrigger key={item.key} value={item.key}>
              <span>
                {renderTabTitle
                  ? renderTabTitle({ item, index, items })
                  : item.name}
              </span>
            </TabsTrigger>
          ))}
      </TabsList>
      {items.map((item, index) => (
        <TabsContent key={item.key} value={item.key}>
          {renderContent({ item, index })}
        </TabsContent>
      ))}
    </TabsWrapper>
  );
}

Tabs.propTypes = {
  defaultKey: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.objectOf({
    key: PropTypes.string.isRequired,
    name: PropTypes.string,
  })),
  renderTabTitle: PropTypes.func,
  renderTabs: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
};
