import { getDeviceId } from 'core/localStore';

export function sortedKeysFromEditorMeta(metaMap, options) {
  if (!metaMap) {
    return [];
  }
  const { includeDeleted = true } = options ?? {};

  const keys = Object.keys(metaMap)
    .filter((x) => metaMap[x].ts && metaMap[x].key && (includeDeleted || !metaMap[x].is_deleted))
    .sort((x, y) => metaMap[x].index - metaMap[y].index);
  return keys;
}

export function sortedMetaFromEditorMeta(metaMap, options) {
  const keys = sortedKeysFromEditorMeta(metaMap, options);
  return keys.map((key) => metaMap[key]);
}

export function sortedItemsFromEditorMeta(items, metaMap, options) {
  return sortedMetaFromEditorMeta(metaMap, options).map((mitem) => items[mitem.findex]);
}

export function sortedIndexesFromEditorMeta(items, metaMap, options) {
  return sortedMetaFromEditorMeta(metaMap, options).map((mitem) => mitem.findex);
}

export function filterMetaUsingPath(metaMap, path) {
  if (!path || path.length === 0) {
    return metaMap;
  }

  let current = metaMap;
  for (let i = 0; i < path.length; i += 1) {
    const key = path[i];
    current = current[key]?.children ?? null;
    if (current === null) {
      break;
    }
  }

  return current;
}

export function newEditorMetaItem(metaMap, reqIndex = null) {
  const keys = sortedKeysFromEditorMeta(metaMap);
  const findex = keys.length;
  const index = reqIndex !== null ? reqIndex : findex;
  const ts = parseInt((+new Date()) / 1000, 10);
  const key = [getDeviceId(), `${ts}`, `${findex}`].join('-');
  const changes = [];

  if (reqIndex !== null) {
    const sortedKeys = sortedKeysFromEditorMeta(metaMap);
    for (let i = reqIndex; i < sortedKeys.length; i += 1) {
      const item = metaMap[sortedKeys[i]];
      changes.push({
        key: item.key,
        index: item.index + 1,
      });
    }
  }

  return [changes, {
    key,
    index,
    findex,
    ts,
    is_deleted: false,
    children: {},
  }];
}

export function newEditorMetaFromItems(items) {
  const metaMap = {};
  Object.keys(items).forEach(() => {
    // console.log('newEditorMetaFromItems', key);
    const [, item] = newEditorMetaItem(metaMap);
    metaMap[item.key] = item;
  });
  return metaMap;
}

export function editorMetaUpdateIndex(metaMap, key, newIndex) {
  const changes = [];
  const sortedKeys = sortedKeysFromEditorMeta(metaMap);
  const item = metaMap[key];
  const oldIndex = item.index;
  if (oldIndex === newIndex) {
    return [];
  }
  if (oldIndex < newIndex) {
    for (let i = oldIndex + 1; i <= newIndex; i += 1) {
      const itemKey = sortedKeys[i];
      const change = {
        key: itemKey,
        index: i - 1,
      };
      changes.push(change);
    }
  } else {
    for (let i = newIndex; i < oldIndex; i += 1) {
      const itemKey = sortedKeys[i];
      const change = {
        key: itemKey,
        index: i + 1,
      };
      changes.push(change);
    }
  }
  return changes;
}

export function editorMetaAddChild(metaMap, metaPath, parentKey, atFirst) {
  const directParent = filterMetaUsingPath(metaMap, metaPath);
  // console.log('editorMetaAddChild', directParent, metaMap, metaPath, parentKey, atFirst);
  const pathParts = [];
  if (metaPath) {
    for (let i = 0; i < metaPath.length; i += 1) {
      pathParts.push(`${metaPath[i]}.children`);
    }
  }
  pathParts.push(`${parentKey}.children`);
  const parent = directParent[parentKey];
  if (!parent) {
    return null;
  }
  const index = atFirst ? 0 : null;
  const [changes, metaItem] = newEditorMetaItem(parent.children ?? {}, index);
  return [pathParts.join('.'), changes, metaItem];
}

// export function editorMetaFieldKey(metaFieldName, fieldName) {
//   console.log('editorMetaFieldKey', metaFieldName, fieldName);
//   // const newName = fieldName.replace(/\./g, '__');
//   // return `${metaFieldName}.${newName}`;
//   return `${metaFieldName}`;
// }

export function editorMetaPrepareForSave(data) {
  if (!data) {
    return [];
  }
  const allKeys = sortedKeysFromEditorMeta(data);
  if (allKeys.length === 0) {
    return [];
  }
  return allKeys.map((key) => {
    const value = data[key];
    if (value && value.children) {
      value.children = editorMetaPrepareForSave(value.children);
    } else if (value) {
      value.children = [];
    }
    return { key, value };
  });
}

export function editorMetaPrepareForEdit(data) {
  if (!data) {
    return null;
  }
  const metaMap = {};
  // console.log('editorMetaPrepareForEdit', data);
  data.forEach((item) => {
    metaMap[item.key] = item.value;
    if (item.value.children) {
      metaMap[item.key].children = editorMetaPrepareForEdit(item.value.children);
    } else {
      metaMap[item.key].children = {};
    }
  });
  return metaMap;
}

export function editorMetaCleanUp(items, metaMap) {
  const newItems = { ...items };
  const newMetaMap = { ...metaMap };
  let keys = sortedKeysFromEditorMeta(newMetaMap);

  // Remove deleted items
  keys.forEach((key) => {
    if (metaMap?.[key]?.is_deleted) {
      // Push the index of the deleted item to the end
      const { findex } = newMetaMap[key];
      for (let i = 0; i < keys.length; i += 1) {
        const itemKey = keys[i];
        if (newMetaMap?.[itemKey]?.findex > findex) {
          newMetaMap[itemKey].findex = i - 1;
        }
      }
      delete newItems[key];
      delete newMetaMap[key];
    }
  });

  // Reset the index of the items
  keys = sortedKeysFromEditorMeta(newMetaMap);
  keys.forEach((key, index) => {
    newMetaMap[key].index = index;
  });

  // Clean up children
  keys.forEach((key) => {
    const item = newMetaMap[key];
    if (item.children) {
      const [newChildren, newChildMetaMap] = editorMetaCleanUp(
        newItems[key].children,
        item.children,
      );
      newItems[key].children = newChildren;
      newMetaMap[key].children = newChildMetaMap;
    }
  });
  return [newItems, newMetaMap];
}

export function objectAsArrayFromMeta({
  val, metaMap,
  childrenField = null,
  defaultValue = undefined,
  nullAs = null,
  newObjFunc = null,
} = {}) {
  if (val instanceof Array) {
    return val;
  }
  if (!val) {
    return [];
  }
  const keys = sortedKeysFromEditorMeta(metaMap);

  if (keys.length === 0) {
    return [];
  }

  const items = [];
  keys.forEach((key) => {
    let itemVal = val[key];
    if (itemVal === undefined) {
      itemVal = defaultValue;
    }
    if (itemVal === null) {
      itemVal = nullAs;
    }

    if (itemVal !== null && newObjFunc) {
      itemVal = newObjFunc(itemVal);
    }

    if (childrenField) {
      if (itemVal[childrenField]) {
        itemVal[childrenField] = objectAsArrayFromMeta({
          val: itemVal[childrenField],
          metaMap: metaMap[key].children,
          childrenField,
          newObjFunc,
          nullAs,
          defaultValue,
        });
      } else {
        itemVal[childrenField] = [];
      }
    }

    if (itemVal !== undefined) {
      items.push(itemVal);
    }
  });
  return items;
}

export function arrayAsObjectFromMeta({
  val,
  metaMap,
  childrenField = null,
  newObjFunc = null,
}) {
  if (!(val instanceof Array)) {
    return val;
  }
  const keys = sortedKeysFromEditorMeta(metaMap);
  const items = {};
  keys.forEach((key) => {
    let item = val[metaMap[key].findex];
    if (newObjFunc) {
      item = newObjFunc(item);
    }
    if (childrenField) {
      if (item[childrenField]) {
        item[childrenField] = arrayAsObjectFromMeta({
          val: item[childrenField],
          metaMap: metaMap[key].children,
          childrenField,
          newObjFunc,
        });
      } else {
        console.log('No children field', metaMap, key, item);
        item[childrenField] = {};
      }
    }
    items[metaMap[key].key] = item;
  });
  return items;
}

export function editorMetaCleanUpAndConvert({
  obj,
  itemsProp,
  metaMapProp = null,
  cleanUp,
  childrenField = null,
  cleanUpFunc = null,
}) {
  const newObj = { ...obj };
  if (!newObj[itemsProp]) {
    newObj[itemsProp] = {};
  }
  const items = newObj[itemsProp];
  const metaProp = metaMapProp || `${itemsProp}__Meta`;

  if (cleanUp) {
    const [newItems, newMetaMap] = editorMetaCleanUp(items, newObj[metaProp]);
    newObj[itemsProp] = newItems;
    newObj[metaProp] = newMetaMap;
  }

  newObj[itemsProp] = objectAsArrayFromMeta({
    val: newObj[itemsProp],
    metaMap: newObj[metaProp],
    childrenField,
  });

  if (!metaMapProp) {
    if (cleanUp) {
      delete newObj[metaProp];
    }
  } else {
    newObj[metaMapProp] = editorMetaPrepareForSave(newObj[metaMapProp]);
  }

  newObj[itemsProp] = newObj[itemsProp].map((item) => {
    let newItem = { ...item };
    if (childrenField && item.children) {
      newItem = editorMetaCleanUpAndConvert({
        obj: item,
        itemsProp: 'children',
        metaMapProp,
        cleanUp,
        cleanUpFunc,
      });
    } else if (!childrenField && item.children) {
      delete newItem.children;
    }
    return newItem;
  });

  if (cleanUp && cleanUpFunc) {
    newObj[itemsProp] = newObj[itemsProp].map(cleanUpFunc);
  }

  return newObj;
}

export function editorMetaPrepareItem({
  obj,
  itemsProp,
  metaMapProp = null,
  childrenField = null,
  createFunc = null,
}) {
  const newObj = { ...obj };
  const items = newObj[itemsProp];
  if (!items) {
    return newObj;
  }
  const metaProp = metaMapProp || `${itemsProp}__Meta`;
  let metaMap = newObj[metaProp];
  if (!metaMap) {
    metaMap = newEditorMetaFromItems(items);
  } else {
    metaMap = editorMetaPrepareForEdit(metaMap);
  }

  newObj[itemsProp] = arrayAsObjectFromMeta({
    val: newObj[itemsProp],
    metaMap,
    childrenField,
  });

  newObj[metaProp] = metaMap;

  if (createFunc) {
    const newItems = {};
    Object.keys(newObj[itemsProp]).forEach((key) => {
      newItems[key] = createFunc(newObj[itemsProp][key]);
    });
    newObj[itemsProp] = newItems;
  }
  return newObj;
}
