import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/client';
import { MyProfileQuery } from 'user/query';
import { AccountsGetQuery } from 'account/query';
import { useNavigate } from 'react-router-dom';
import { AppScreenError, AppScreenLoading } from './Screen';
import {
  getToken, removeToken,
  saveAccountKey, getAccountKey, getDeviceId, saveNewDeviceId,
  getSport, saveSport,
  savePreferredTimeZone, getPreferredTimeZone,
  saveSelectedWebsite,
  saveActiveTab,
  saveSelectedWebsiteName,
  getSelectedWebsite,
} from './localStore';
import { AppContext } from './AppContext';

const defaultContext = {
  user: null,
  loggedIn: false,
  roanuzMode: false,
  showLogin: false,
  accountKey: getToken() && getAccountKey(),
  preferredTimeZone: getPreferredTimeZone(),
  deviceKey: getDeviceId() || saveNewDeviceId(),
  account: null,
  sport: getToken() && getSport(),
  accountFeatures: {},
  selectedWebsite: getSelectedWebsite() || null,
  selectedTree: null,
};

export const UserContext = React.createContext({ ...defaultContext });
export const UserContextConsumer = UserContext.Consumer;

export function UserContextProvider({ value, children }) {
  const appContext = useContext(AppContext);
  const apolloClient = useApolloClient();
  const [userContext, setUserContext] = useState({
    ...defaultContext,
    setLoggedIn: (loggedIn) => {
      setUserContext((state) => ({
        ...state,
        loggedIn,
      }));
    },
    setRoanuzMode: (roanuzMode) => {
      setUserContext((state) => ({
        ...state,
        roanuzMode,
      }));
    },
    setAccountFeatures: (features) => {
      setUserContext((state) => ({
        ...state,
        accountFeatures: {
          ...features,
        },
      }));
    },
    setShowLogin: (showLogin) => {
      setUserContext((state) => ({
        ...state,
        showLogin,
      }));
    },
    setUser: (user) => {
      let loggedIn = false;
      if (user) {
        loggedIn = true;
      }
      setUserContext((state) => ({
        ...state,
        user,
        loggedIn,
      }));
    },
    logout: () => {
      // Step 1 - Remove Local storage
      removeToken();
      saveSport(null);
      saveAccountKey(null);
      saveSelectedWebsite(null);
      saveSelectedWebsiteName(null);
      saveActiveTab(null);

      // Step 2 - Clear local state
      setUserContext((state) => ({
        ...state,
        loggedIn: false,
        user: null,
        accountKey: null,
        account: null,
        sport: null,
      }));

      // Step 3 - Clear apollo cache
      apolloClient.resetStore();

      // Actually Step 2 & 3 not required if page is refreshed
      window.location = '/';
    },
    setAccountKey: (accountKey) => {
      setUserContext((state) => ({
        ...state,
        accountKey,
      }));
      saveAccountKey(accountKey);
      apolloClient.resetStore();
      setUserContext((state) => {
        state.setSport('');
        return state;
      });
    },
    setPreferredTimeZone: (timezone) => {
      setUserContext((state) => ({
        ...state,
        timezone,
      }));
      savePreferredTimeZone(timezone);
      apolloClient.resetStore();
    },
    setSport: (sport) => {
      setUserContext((state) => ({
        ...state,
        sport,
      }));
      saveSport(sport);
      apolloClient.resetStore();
    },
    setAccount: (account) => {
      setUserContext((state) => ({
        ...state,
        account,
      }));
      localStorage.setItem('rzaccount', JSON.stringify(account));
    },
    setSelectedWebsite: (selectedWebsite) => {
      setUserContext((state) => ({
        ...state,
        selectedWebsite,
      }));
      saveSelectedWebsite(selectedWebsite);
      apolloClient.resetStore();
    },
    setSelectedTree: (selectedTree) => {
      setUserContext((state) => ({
        ...state,
        selectedTree,
      }));
    },
    ...value,
  });

  const token = getToken();
  const navigate = useNavigate();
  const accountKey = getAccountKey();
  const { loading, error, data } = useQuery(MyProfileQuery, {
    skip: !token,
  });

  const shouldLoadAccount = accountKey && (userContext.account === null);
  const { error: accountError, data: accountData } = useQuery(AccountsGetQuery, {
    variables: { key: accountKey },
    skip: !shouldLoadAccount,
  });

  if (accountError) {
    console.log('Error loading Account', accountError);
  }

  useEffect(() => {
    console.log('Change layout here', appContext.viewState.left);
    // if (userContext.loggedIn && userContext.accountKey) {
    //   appContext.setViewStateValue('left', true);
    // } else {
    //   appContext.setViewStateValue('left', false);
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.loggedIn, userContext.accountKey]);

  useEffect(() => {
    if (userContext.user && !userContext.loggedIn) {
      userContext.setLoggedIn(true);
      if (userContext?.user?.accounts?.accounts?.length === 0
        && userContext.user.accounts.invitations_count === 0) {
        navigate('/pricing');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.user, userContext.loggedIn]);

  useEffect(() => {
    if (data && data.user_profile && !userContext.user) {
      userContext.setUser(data.user_profile.user);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (accountData && accountData.account_get) {
      userContext.setAccount(accountData.account_get.account);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  useEffect(() => {
    if (error && error.graphQLErrors) {
      error.graphQLErrors.forEach((errorItem) => {
        if (errorItem.extensions && errorItem.extensions.rzerror) {
          const errorData = errorItem.extensions.rzerror;
          if (errorData.class === 'InvalidTokenError') {
            userContext.logout();
          }
        }
      });
      console.log('Error', error.graphQLErrors);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (loading) return (<AppScreenLoading />);
  if (error) {
    return (<AppScreenError error={error} />);
  }

  return (
    <UserContext.Provider value={userContext}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.element,
};
