import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';

export const WebsiteCreateHintQuery = gql`
query WebsiteCreateHintQuery($name: String!) {
  cms_website_create_hint(name: $name) {
    name
    similar_items {
      resource {
        account
        website
        hashkey: _hashkey
      }
      name {
        name
      }
    }
    key_suggestions
  }
}
`;

export const WebsiteCreateMutation = gql`
mutation WebsiteCreate(
  $website: WebsiteWebsiteInput!,
  $key: String,
) {
  cms_website_create(
    website: $website,
    key: $key) {
      status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const WebsiteReadQuery = gql`
query WebsiteReadQuery($resource: WebsiteInput!, $withHistory: Boolean) {
  cms_website_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        website
        hashkey: _hashkey
      }
      name { name }
      website {
        name
        url
        is_default
        variables {name, value}
        options {name, value { name, value }}
      }
      stats {
        children_count
        total_pages
      }
      props {
        props {
          title
          desc
          breadcrumb_name
          title_suffix
          desc_suffix
          keywords
          access {
            req_user
            req_account
            req_role
            for_users
            for_accounts
            req_account_role
          }
          head_items {
            link {
              id
              attrs {
                key
                value
              }
              index
              rel
              href
              l_as
              crossorigin
              media
              onload
            }
            tag {
              id
              attrs {
                key
                value
              }
              index
              tag
              content
            }
            script {
              id
              attrs {
                key
                value
              }
              index
              tag
              content
              src
              defrs
            }
          }
          body_begin_items {
            link {
              id
              attrs {
                key
                value
              }
              index
              rel
              href
              l_as
              crossorigin
              media
              onload
            }
            tag {
              id
              attrs {
                key
                value
              }
              index
              tag
              content
            }
            script {
              id
              attrs {
                key
                value
              }
              index
              tag
              content
              src
              defrs
            }
          }
          body_end_items {
            link {
              id
              attrs {
                key
                value
              }
              index
              rel
              href
              l_as
              crossorigin
              media
              onload
            }
            tag {
              id
              attrs {
                key
                value
              }
              index
              tag
              content
            }
            script {
              id
              attrs {
                key
                value
              }
              index
              tag
              content
              src
              defrs
            }
          }
          og {
            title
            desc
            title_prefix
            desc_prefix
            img {
              account
              key
              hashkey: _hashkey
            }
            url
            attrs {
              key
              value
            }
          }
          headers {
            status
            new_loc
            new_slug
            new_tree_key
            headers {
              key
              value
            }
          }
        }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const WebsiteUpdateMutation = gql`
mutation WebsiteUpdateMutation(
  $resource: WebsiteInput!,
  $props: WebsitePropsInput,
  $website: WebsiteWebsiteInput!,
  $version: RecordStage) {
  cms_website_update(
    resource: $resource,
    website: $website,
    props: $props,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const WebsiteDeleteMutation = gql`
mutation  WebsiteDeleteMutation(
  $resource: WebsiteInput!,
  $name: String!) {
  cms_website_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const WebsiteSearchQuery = gql`
query WebsiteSearchQuery($search: String!, $page: PagedRequestInput) {
  cms_website_search(search: $search, page: $page) {
    items {
      resource {
        account
        website
        hashkey: _hashkey
      }
      name {
        name
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const WebsiteNameQuery = gql`
query WebsiteNameQuery($resource: WebsiteInput!) {
  cms_website_name(resource: $resource) {
    name
  }
}
`;
