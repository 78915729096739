import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ActiveUserView } from 'core/Image';
import { asRem } from 'lib/css';
import { UserContext } from 'core/UserContext';
import { dataFromPath, capitilise } from 'lib/utils';
import { Pages } from 'core/pages';
import { getResourceDef } from 'sports/resources';
import { Link } from 'react-router-dom';
import { AppContext, AppLayoutWidth } from 'core/AppContext';
import { ReactComponent as EmptyStateIcon } from 'assets/icons/icon-empty-state.svg';
import { Hover } from 'core/Hover';
import { Label12Regular, Text14Regular } from 'style/typography';
import { Popover } from 'core/Popover';
import { RealtimeContext, topicPrefix } from './RealtimeContext';
import { getActiveUserClass } from './colorClass';

const ActiveUsersWrapper = styled.div`
ul {
  display: flex;
  gap: ${asRem(8)};
  align-items: center;
  .more-users-indicator {
    --i-size: ${asRem(34)};
    border-radius: 50%;
    min-width: var(--i-size);
    height: var(--i-size);
    line-height: var(--i-size);
    text-align: center;
    background: var(--color-border-1);
    color: var(--color-text-1);
  }

}
.users-grid {
  display: flex;
  max-width: ${asRem(240)};
  gap: ${asRem(10)};
  flex-wrap: wrap;
  justify-content: center;
}
`;

export function ActiveUsers({
  topic,
  userColors,
  size,
  maxUsers = null,
  resourceFilter = null,
}) {
  if (!topic || topic.activeUsers.items.length === 0) {
    return null;
  }
  let resolvedUsers = topic.activeUsers.items;
  if (resourceFilter) {
    resolvedUsers = resolvedUsers
      .filter((au) => au.interaction && au.interaction.resourceKey === resourceFilter);
  }
  let users = resolvedUsers;

  if (resolvedUsers.length === 0) {
    return null;
  }

  let moreUsers = [];
  if (maxUsers) {
    moreUsers = users.length - maxUsers;
    users = resolvedUsers.slice(0, maxUsers);
    moreUsers = resolvedUsers.slice(maxUsers);
  }

  return (
    <ActiveUsersWrapper>
      <ul>
        {users.map((activeUser) => (
          <li
            key={activeUser.pathKey}
            className={userColors[activeUser.pathKey]}
          >
            <ActiveUserView
              activeUser={activeUser}
              colorClass={userColors[activeUser.pathKey]}
              size={size}
            />
          </li>
        ))}
        {moreUsers.length > 0 && (
          <li>
            <Hover openDelay={300}>
              <Hover.Trigger>
                <div className="more-users-indicator">
                  {`+${moreUsers.length}`}
                </div>
              </Hover.Trigger>
              <Hover.Content>
                <div className="users-grid">
                  {moreUsers.map((activeUser) => (
                    <div
                      key={activeUser.pathKey}
                    >
                      <ActiveUserView
                        activeUser={activeUser}
                        colorClass={userColors[activeUser.pathKey]}
                        size={size}
                      />
                    </div>
                  ))}
                </div>
              </Hover.Content>
            </Hover>
          </li>
        )}
      </ul>
    </ActiveUsersWrapper>
  );
}

ActiveUsers.propTypes = {
  topic: PropTypes.object,
  userColors: PropTypes.object,
  size: PropTypes.string,
  maxUsers: PropTypes.number,
  resourceFilter: PropTypes.string,
};

const TopicActiveUsersWrapper = styled.div`

`;

export function TopicActiveUsers({
  topicPath,
  size,
  maxUsers = null,
  ...activeUserProps
}) {
  const rtContext = useContext(RealtimeContext);
  const topicKey = rtContext.topics
        && Object.keys(rtContext.topics).find((t) => t.endsWith(topicPath));
  const topic = rtContext.topics[topicKey];
  if (!topic) {
    return null;
  }

  return (
    <TopicActiveUsersWrapper>
      <ActiveUsers
        {...activeUserProps}
        topic={topic}
        userColors={topic.userColors}
        size={size}
        maxUsers={maxUsers}
      />
    </TopicActiveUsersWrapper>
  );
}

TopicActiveUsers.propTypes = {
  topicPath: PropTypes.string,
  size: PropTypes.string,
  maxUsers: PropTypes.number,
};

const UserInteractionWrapper = styled.div`
  &.list-view {
    display: flex;
    justify-content: space-between;
    > div {
      background: none;
      text-align: left;
      max-width: 100%;
      padding: 0;
      .activity-wrapper {
        display: flex;
      }
    }
    > a {
      svg {
        fill: var(--color-secondary-cta);
        margin-top: ${asRem(11)};
      }
    }
    .activity-link {
      padding: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .active-users {
      display: inline-block;
      vertical-align: top;
    }
    .title-card {
      margin-top: ${asRem(10)};

      .label-12-regular {
        word-break: break-word;
      }
    }
    .interaction-title {
      display: inline-block;
      vertical-align: top;
      max-width: ${asRem(88)};
      p {
        padding-bottom: ${asRem(2)};
        color: var(--color-text-2);
      }
      span {
        display: inline-block;
        vertical-align: top;
        max-width: ${asRem(270)};
        color: var(--color-secondary-cta);
      }
    }
  }

svg {
  fill: var(--color-realtime);
  height: ${asRem(12)};
}
`;

export function UserInteraction({
  userActivity,
  size,
  index = 0,
  list,
  allResource,
  renderContent,
}) {
  const { interaction } = userActivity;
  const activity = interaction ?? {};
  const resourceDef = activity.resourceType ? getResourceDef(
    capitilise(activity.resourceType),
  ) : null;
  const isMutation = activity.isMutation || false;
  let page;
  if (resourceDef && !activity.page) {
    page = dataFromPath(Pages, `${resourceDef.pageKey}`);
  } else {
    page = activity.page ? dataFromPath(Pages, activity.page) : null;
    if (page === undefined) {
      page = resourceDef && resourceDef.pageKey ? dataFromPath(Pages, `${resourceDef.pageKey}.${activity.action}`) : null;
    }
  }
  let action = null;
  let actionName = null;
  let title = activity.resourceName ?? null;

  if (resourceDef && activity.action) {
    action = (isMutation)
      ? resourceDef.mutations[activity.action]
      : resourceDef.queries[activity.action];
    actionName = action?.name;
  }

  if (!title && action) {
    title = action.name;
  }

  if (!title && page) {
    title = page.meta.name;
  }

  if (!title && page) {
    title = page.meta.name;
  }

  if (title === actionName) {
    actionName = null;
  }

  let titleView = null;
  let titleText = null;
  if (interaction || activity) {
    titleText = `on ${allResource ? activity.resourceType : ''} ${activity.action} screen`;
  }

  if (interaction && list) {
    titleView = (
      <div className="title-card">
        {activity && (
          <Label12Regular>
            On
            {' '}
            {allResource ? activity.resourceType : ''}
            {' '}
            {activity.action}
            {' '}
            screen
          </Label12Regular>
        )}
      </div>
    );
  }

  if (renderContent) {
    return (
      renderContent(titleView, activity, page, interaction, titleText)
    );
  }

  return (
    <UserInteractionWrapper className={list ? 'list-view' : 'default-view'}>
      {!list
        ? (
          <div>
            <ActiveUserView
              activeUser={userActivity}
              colorClass={getActiveUserClass(index)}
              size={size}
            />
            {interaction && page && titleView && (
            <Link className="clean" to={page.meta.urlFor(activity.pageParams) || '/'}>
              {titleView}
            </Link>
            )}
            {interaction && !page && titleView}
          </div>
        )
        : (
          <Link className="clean activity-link" to={page ? page.meta.urlFor(activity.pageParams) : '/'}>
            <div className="activity-wrapper">
              <ActiveUserView
                activeUser={userActivity}
                colorClass={getActiveUserClass(index)}
                size={size || '34'}
              />
              <div>
                {interaction && page && (
                <div className="interaction-title">
                  {titleView}
                </div>
                )}
                {interaction && !page && (
                <div className="interaction-title">
                  {titleView}
                </div>
                )}
              </div>
            </div>
          </Link>
        )}
    </UserInteractionWrapper>
  );
}

UserInteraction.propTypes = {
  userActivity: PropTypes.object,
  index: PropTypes.number,
  size: PropTypes.number,
  list: PropTypes.bool,
  allResource: PropTypes.bool,
  renderContent: PropTypes.func,
};

const AccountActiveUsersWrapper = styled.div`
  padding: ${asRem(20)};
  .users {
    display: flex;
    gap: ${asRem(80)};
    flex-wrap: wrap;
  }
`;

export function AccountActiveUsers({ size }) {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const rtContext = useContext(RealtimeContext);
  const [topic, setTopic] = useState(null);

  useEffect(() => {
    const topicPath = topicPrefix(userContext);
    const activeTopic = rtContext.topics?.[topicPath];
    setTopic(activeTopic);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtContext.topics]);

  useEffect(() => {
    appContext.setLayoutWidth(AppLayoutWidth.One);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccountActiveUsersWrapper>
      {!topic
        ? (
          <div>...</div>
        )
        : (
          <div className="users">
            {topic.activeUsers.items.filter((x) => x.pathKey).map((user, index) => (
              <div key={user.pathKey} className="user-item">
                <UserInteraction
                  userActivity={user}
                  index={index}
                  size={size}
                />
              </div>
            ))}
          </div>
        )}
    </AccountActiveUsersWrapper>
  );
}

AccountActiveUsers.propTypes = {
  size: PropTypes.number,
};

const ResourceActiveUsersWrapper = styled.div`
  .users {
    display: flex;
    gap: ${asRem(10)};
    align-items: center;
  }
  .error-state {
    flex: 0 0 100%;
    text-align: center;

    .label-12-regular {
      display: block;
      margin-top: ${asRem(10)};
    }
  }

  .remaining-users {
    border: ${asRem(1)} solid var(--color-border-1);
    border-radius: 50%;
    height: ${(props) => asRem(props.size)};
    width:  ${(props) => asRem(props.size)};


    button {
      padding: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: inherit;
      height: inherit;
      color: white;

      span {
        margin: auto;
      }
    }
  }

`;

const UserWrapper = styled.div`
  .users-list {
    display: flex;
    gap: ${asRem(16)};
    align-items: flex-start;
    margin-bottom: ${asRem(16)};

    a{
      padding: 0;
      display: block;
    }
  }
`;

export function ResourceActiveUsers({
  resourceType,
  resourceKey,
  renderUserView = null,
  list,
  allResource,
  getResourceBasedUsers,
  resourceBasedUsers,
  size,
  maxSize,
  ignoreActions = null,
}) {
  const userContext = useContext(UserContext);
  const rtContext = useContext(RealtimeContext);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showAllusers, setShowAllUsers] = useState(false);
  const [remainingUsers, setRemainingUsers] = useState(0);

  useEffect(() => {
    const topicPath = topicPrefix(userContext);
    const activeTopic = rtContext.topics?.[topicPath];
    if (activeTopic) {
      const updatedUsers = activeTopic.activeUsers.items.filter((x) => {
        if (x.interaction && x.interaction.resourceType === resourceType) {
          if (resourceKey) {
            const matched = x.interaction.resourceKey === resourceKey;
            if (matched && ignoreActions) {
              return matched && !ignoreActions.includes(x.interaction.action);
            }
            return matched;
          }
          return true;
        }

        return false;
      });
      if (maxSize) {
        setUsers(updatedUsers.slice(0, maxSize));
        setRemainingUsers(updatedUsers.length - maxSize);
      } else {
        setUsers(updatedUsers);
      }
      setAllUsers(updatedUsers);
    } else {
      setUsers([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtContext.topics]);

  useEffect(() => {
    if (users?.length > 0 && allResource) {
      const arr = [...resourceBasedUsers] || [];
      const resource = {
        resource: resourceType,
        users: users.length,
      };
      const filteredVal = arr.filter((val) => val.resource === resourceType);
      if (filteredVal.length === 0) {
        arr.push(resource);
        getResourceBasedUsers(arr);
      }
    }
  }, [users, resourceType, resourceBasedUsers, getResourceBasedUsers, allResource]);

  return (
    <ResourceActiveUsersWrapper size={size}>
      <div className="user-wrapper">
        {users.length > 0 ? (
          <div className="users">
            {users.map((user, index) => (
              <div key={user.pathKey} className="user-item">
                {renderUserView
                  ? renderUserView({ userActivity: user, interaction: user.interaction, index })
                  : (
                    <ActiveUserView
                      size={size}
                      activeUser={user}
                      colorClass={getActiveUserClass(index)}
                    />
                  )}
              </div>
            ))}
            {
              remainingUsers > 0 && (
                <div className="remaining-users">
                  <Popover
                    open={showAllusers}
                    onOpenChange={(currState) => setShowAllUsers(currState)}
                  >
                    <Popover.Trigger>
                      <button
                        type="button"
                        onClick={() => setShowAllUsers(true)}
                        onMouseOver={() => setShowAllUsers(true)}
                        onFocus={() => setShowAllUsers(true)}
                        onMouseLeave={() => setShowAllUsers(false)}
                      >
                        <Label12Regular>{`+${remainingUsers}`}</Label12Regular>
                      </button>
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content>
                        <div>
                          {allUsers.map((user, index) => (
                            <UserWrapper>
                              <UserInteraction
                                userActivity={user}
                                index={index}
                                size={size}
                                allResource={allResource}
                                list
                                renderContent={(titleView, activity, page) => (
                                  <div className="users-list">
                                    <ActiveUserView
                                      activeUser={user}
                                      colorClass={getActiveUserClass(index)}
                                      size={size}
                                      showName={false}
                                    />
                                    <div>
                                      <Text14Regular>{user.user.first_name}</Text14Regular>
                                      {titleView && (
                                      <Link
                                        className="plain"
                                        to={page ? page.meta.urlFor(activity.pageParams) : '/'}
                                      >
                                        {titleView}
                                      </Link>
                                      )}
                                    </div>
                                  </div>
                                )}
                              />

                            </UserWrapper>
                          ))}
                        </div>
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover>
                </div>
              )
            }
          </div>
        ) : (
          <div className="error-state">
            {list && !allResource && (
              <>
                <EmptyStateIcon />
                <Label12Regular>No Live collaborators</Label12Regular>
              </>
            )}
          </div>
        )}
      </div>
    </ResourceActiveUsersWrapper>
  );
}

ResourceActiveUsers.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceKey: PropTypes.string,
  renderUserView: PropTypes.func,
  size: PropTypes.string,
  list: PropTypes.bool,
  getResourceBasedUsers: PropTypes.func,
  resourceBasedUsers: PropTypes.array,
  allResource: PropTypes.bool,
  maxSize: PropTypes.number,
  ignoreActions: PropTypes.array,
};

const ResourceTypeActiveUsersListWrapper = styled.div`
  .users {
    display: flex;
    ${'' /* flex-direction: column;
    gap: ${asRem(10)}; */}
  }
`;

export function ResourceTypeActiveUsersList({
  resourceType,
  resourceKey,
  list,
  allResource,
  getResourceBasedUsers,
  resourceBasedUsers,
  size,
  ignoreActions = null,
}) {
  return (
    <ResourceTypeActiveUsersListWrapper className={resourceType}>
      <ResourceActiveUsers
        resourceType={resourceType}
        resourceKey={resourceKey}
        list={list}
        getResourceBasedUsers={getResourceBasedUsers}
        resourceBasedUsers={resourceBasedUsers}
        allResource={allResource}
        ignoreActions={ignoreActions}
        renderUserView={({ userActivity, index }) => (
          <UserInteraction
            userActivity={userActivity}
            index={index}
            list={list}
            size={size}
            allResource={allResource}
          />
        )}
      />
    </ResourceTypeActiveUsersListWrapper>
  );
}

ResourceTypeActiveUsersList.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceKey: PropTypes.string,
  list: PropTypes.bool,
  size: PropTypes.number,
  getResourceBasedUsers: PropTypes.func,
  resourceBasedUsers: PropTypes.array,
  allResource: PropTypes.bool,
  ignoreActions: PropTypes.array,
};
