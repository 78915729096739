import {
  ApolloClient, InMemoryCache,
  HttpLink,
  ApolloLink,
  from as linkFrom,
  defaultDataIdFromObject,
} from '@apollo/client';

// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context';

import { Config } from 'config';
import { createApolloCacheKey } from 'lib/utils';
import { getToken, getAccountKey } from './localStore';

const logLink = new ApolloLink((operation, forward) => {
  // const timerName = `===>⏳ Request - ${operation.operationName}`;
  // console.time(timerName);
  const startedAt = (new Date()).getTime();
  return forward(operation).map((result) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const cacheTag = (headers && headers.get('x-cache')) || 'N/A';
    const totalTime = (new Date()).getTime() - startedAt;
    console.log(`===>⏳ Cache Tag ${operation.operationName}: ${cacheTag} ${totalTime}ms`);
    // console.timeEnd(timerName);
    return result;
  });
});

const authLink = (userToken) => setContext((request, { headers }) => {
  let token = userToken;
  let account = null;
  if (typeof window !== 'undefined') {
    token = getToken();
    account = getAccountKey();
  }
  return {
    headers: {
      ...headers,
      rzoperationname: request.operationName,
      rzapikey: Config.apiKey,
      rztoken: token || '',
      rzaccountkey: account,
    },
  };
});

function createIsomorphLink() {
  return new HttpLink({
    uri: Config.gqlSrc,
    credentials: 'same-origin',
  });
}

function createApolloClient(userToken, initialState) {
  // const link = authLink(userToken).concat(createIsomorphLink());
  const links = [
    authLink(userToken),
    createIsomorphLink(),
  ];

  if (!Config.IsProd) {
    links.unshift(logLink);
  }
  const link = linkFrom(links);

  let cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      // eslint-disable-next-line no-underscore-dangle
      const tn = responseObject.__typename;
      switch (tn) {
        // case 'Product': return `Product:${responseObject.upc}`;
        default:
          if (responseObject.resource) {
            // eslint-disable-next-line no-underscore-dangle
            const newTn = responseObject.resource.__typename;
            if (responseObject.resource.hashkey) {
              return `${newTn}:${responseObject.resource.hashkey}`;
            }
            return createApolloCacheKey(newTn, responseObject.resource);
          }
          // if (responseObject.key) {
          //   return `${tn}:${responseObject.key}`;
          // }
          return defaultDataIdFromObject(responseObject);
      }
    },
  });
  if (initialState) {
    console.log('🧲🧲 Restoring Apollo data');
    cache = cache.restore(initialState);
  } else {
    console.log('⚡️⚡️ New Apollo', userToken);
  }

  return new ApolloClient({
    ssrMode: false,
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        // fetchPolicy: 'cache-and-network',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
}

export function initApollo(
  initialState = null,
  userToken = null,
  // Pages with Next.js data fetching methods, like `getStaticProps`, can send
  // a custom context which will be used by `SchemaLink` to server render pages
) {
  return createApolloClient(userToken, initialState);
}
