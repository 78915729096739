import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StatusEmpty, StatusError, StatusLoading } from 'core/Status';
import { asRem } from 'lib/css';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg';
import { Display20Bold, Text14Regular } from 'style/typography';
import { TournamentResource } from 'sports/cricket/tournament/resource';
import { StadiumResource } from 'sports/stadium/resource';
import { AssociationResource } from 'sports/association/resource';
import { TeamResource } from 'sports/team/resource';
import { CompetitionResource } from 'sports/cricket/competition/resource';
import { RegionResource } from 'sports/region/resource';
import moment from 'moment';
import { ResourceNameView } from './ResourceNameView';

export function FilterNameView({ name, keyVal }) {
  const resources = {
    tournaments: {
      resource: TournamentResource,
      name: 'tournaments',
      isResource: true,
      nameQuery: {
        query: TournamentResource.queries.nameQuery.query,
        resourceNamePath: 'sports_tournament_name.name',
      },
    },
    stadiums: {
      resource: StadiumResource,
      name: 'stadiums',
      isResource: true,
      nameQuery: {
        query: StadiumResource.queries.name.query,
        resourceNamePath: 'sports_stadium_name.name',
      },
    },
    associations: {
      resource: AssociationResource,
      name: 'associations',
      isResource: true,
      nameQuery: {
        query: AssociationResource.queries.name.query,
        resourceNamePath: 'sports_association_name.name',
      },
    },
    teams: {
      resource: TeamResource,
      name: 'teams',
      isResource: true,
      nameQuery: {
        query: TeamResource.queries.name.query,
        resourceNamePath: 'sports_team_name.name',
      },
    },
    competitions: {
      resource: CompetitionResource,
      name: 'competitions',
      isResource: true,
      nameQuery: {
        query: CompetitionResource.queries.name.query,
        resourceNamePath: 'sports_competition_name.name',
      },
    },
    regions: {
      resource: RegionResource,
      name: 'regions',
      isResource: true,
      nameQuery: {
        query: RegionResource.queries.name.query,
        resourceNamePath: 'sports_region_name.name',
      },
    },
  };

  return (
    <>
      {Object.values(resources).map((resource) => (
        <div>
          {name === resource.name && resource.isResource && (
            <ResourceNameView
              resourceDef={resource.resource}
              resourceKey={keyVal}
              nameQuery={resource.nameQuery}
            />
          )}
        </div>
      ))}
    </>
  );
}

FilterNameView.propTypes = {
  name: PropTypes.string,
  keyVal: PropTypes.string,
};

const ListViewWrapper = styled.div`
  width: 100%; 

  &.list-view-wrapper {
    margin: ${asRem(72)} 0;
    .list-view-title-bar {
      display: block;
    }

    > .listview-content {
      > .listview-items {
        ${'' /* .pagination {
          display: none;
        } */}
        .accounts {
          margin-bottom: ${asRem(20)};
        }
      }
    }

    .primary {
      display: block;
    }

  }

  >.listview-title-bar {
    display: flex;
    justify-content: space-between;
    margin: 0 0 ${asRem(18)};

    .actions {
      button {
        margin-left: ${asRem(10)};
      }
    }

    .filters-container {
      padding-top: ${asRem(16)};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: ${asRem(10)};


      &:empty {
        padding: 0;
      }

      .added-tag {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${asRem(6)};
        padding: ${asRem(8)} ${asRem(12)};
        border-radius: ${asRem(4)};
        background-color: var(--color-border-1);
        color: var(--color-secondary-cta);

        svg {
          height: ${asRem(16)};
          width: ${asRem(16)};
          color: var(--color-secondary-cta);
          cursor: pointer;
          vertical-align: middle;
        }
      }
    }
  }

  >.list-view-search-bar {
    margin: 0;
    input {
      width: 100%;
      background-color: var(--color-text-rev);
      margin-bottom: ${asRem(12)};
    }
  }

  >.listview-content {
    >.listview-empty {
      > div {
        margin-bottom: ${asRem(40)};
      }
    }
    >.listview-items {
      table {
        width: 100%;
        font-size: ${asRem(14)};
        line-height: ${asRem(18)};
        thead {
          td {
            color: var(--color-disabled-text);
            padding: ${asRem(16)} var(--layout-fullpage-padding);
            border-bottom: ${asRem(1)} solid var(--color-table-border);
            font-weight: var(--medium-fontWeight);
            input[type=checkbox] {
              border: ${asRem(1)} solid var(--color-text-3);
            }
            &:first-child {
              padding-left: 0;
            }
          }
        }
        tbody {
          td {
            padding: ${asRem(16)} var(--layout-fullpage-padding);
            border-bottom: ${asRem(1)} solid var(--color-table-border);
            color: var(--color-text-1);
            > div {
              display: inline-block;
              vertical-align: middle;
              .active-users {
                margin-right: ${asRem(8)};
              }
            }
            div {
              &.list-actions-wrapper {
                display: flex;
                gap: ${asRem(8)};
                justify-content: end;
                align-items: center;

                >div {
                  display: flex;
                  gap: ${asRem(8)};
                  align-items: center;
                }
              }
            }
            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
      .pagination {
        display: flex;
        flex-direction: row;
        justify-content: end;

        span {
          color: var(--color-label);
          padding-top: ${asRem(8)};
          padding-right: ${asRem(16)};
        }

        button {
          display: block;
          background: var(--color-border-1);
          padding: ${asRem(4)} ${asRem(4)} 0;
          cursor: pointer;
          color: var(--color-label);
          margin-right: ${asRem(8)};
        }
      }
      .page-tools {
        margin: ${asRem(28)} ${asRem(40)};
        display: flex;
        flex-direction: row;
        justify-content: end;
      }
    }
  }
`;

export function ListView({
  title, items, actions, children, search, useTable, renderRowFoot,
  emptyMessage = null,
  loading,
  filterPostVariables,
  addedFilters,
  onTagChange,
  data,
  error,
  renderTitle,
  renderEmpty = null,
  showList = true,
  onChangeFilters,
  additionalFilterVariables,
  renderAdditionalFilterTags,
  showEmptyMsg = true,
}) {
  const resources = ['associations', 'regions', 'stadiums', 'teams', 'tournaments', 'competitions'];
  return (
    <ListViewWrapper className={!useTable ? 'list-view-wrapper listview' : 'listview'}>
      {(title || actions || renderTitle) && (
        <>
          <div className="listview-title-bar">
            {title && (
              <div>
                <Display20Bold>{title}</Display20Bold>
                <div className="filters-container">
                  {addedFilters && addedFilters.map((filter) => (
                    <div className="added-tag">
                      <Text14Regular>{filter}</Text14Regular>
                      <CloseIcon onClick={() => onTagChange(filter)} />
                    </div>
                  ))}
                  {filterPostVariables && Object.entries(filterPostVariables).map((filter) => (
                    filter[1].keys?.length > 0
                        && filter[1]?.keys.map((item) => (
                          <div className="added-tag">
                            {resources.includes(filter[0]) ? (
                              <Text14Regular>
                                <FilterNameView name={filter[0]} keyVal={item} />
                              </Text14Regular>
                            ) : (
                              <Text14Regular>
                                {moment(item, 'YYYY.MM').isValid() ? moment(item, 'YYYY.MM').format('MMM YYYY') : item}
                              </Text14Regular>
                            )}
                            <CloseIcon onClick={() => onChangeFilters(filter)} />
                          </div>
                        ))
                  ))}
                  {
                    additionalFilterVariables
                      && Object.entries(additionalFilterVariables).map((filter) => (
                        <div className="added-tag">
                          {renderAdditionalFilterTags(filter[0], filter[1])}
                          <CloseIcon onClick={() => onChangeFilters(filter, true)} />
                        </div>
                      ))
                  }
                </div>
              </div>
            )}
            {renderTitle && renderTitle()}
            <div className="actions">
              {actions}
            </div>
          </div>
          {search && (
            <div className="list-view-search-bar">
              {search}
            </div>
          )}
        </>
      )}
      <>
        {data && (
          <div className="listview-content">
            {items && items.length > 0 && showList
              && (
                <div className="listview-items">{children}</div>
              )}
            {(!items || items.length === 0) && showList && showEmptyMsg
              && (
                <div className="listview-empty">
                  {renderEmpty
                    ? renderEmpty()
                    : (
                      <StatusEmpty message={emptyMessage} />
                    )}
                    {renderRowFoot && renderRowFoot()}
                </div>
              )}
          </div>
        )}
        {loading && (<StatusLoading message="Hold on..." />)}
        {error && (<StatusError error={error} />)}
      </>
    </ListViewWrapper>
  );
}

ListView.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  renderRowFoot: PropTypes.func,
  renderEmpty: PropTypes.func,
  emptyMessage: PropTypes.string,
  error: PropTypes.any,
  loading: PropTypes.any,
  data: PropTypes.any,
  actions: PropTypes.any,
  search: PropTypes.any,
  addedFilters: PropTypes.array,
  onTagChange: PropTypes.func,
  useTable: PropTypes.bool,
  renderTitle: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showList: PropTypes.bool,
  filterPostVariables: PropTypes.object,
  onChangeFilters: PropTypes.func,
  additionalFilterVariables: PropTypes.object,
  renderAdditionalFilterTags: PropTypes.func,
  showEmptyMsg: PropTypes.bool,
};
