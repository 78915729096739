/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { asRem } from 'lib/css';
import { useQuery } from '@apollo/client';
import { CmsAssetResource } from './resource';

const AssetPreviewWrapper = styled.div`
  &.size-small {
    margin: ${asRem(8)};
    height: ${asRem(100)};
  }
  &.size-tiny {
    height: ${asRem(60)};
    max-width: ${asRem(60)};
  }

  &.size-xs {
    height: ${asRem(20)};
    max-width: ${asRem(20)};

    .loaded {
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  .err, .loading, .img {
    border: 2px solid #cfd0f7;
    background: rgba(22, 28, 35, 0.16);
    border-radius: ${asRem(10)};
    height: 100%;
    width: 100%;
  }

  &.selected {
    .img {
      border-color: green;
    }
  }
  
  .err {
    padding: ${asRem(10)};
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }


    &.loaded {
      opacity: 1;
      position: static;
    }
  }
`;

export function AssetPreview({
  size,
  value,
  defaultLoading = null,
  defaultError = '',
  selected = false,
}) {
  const [loading, setLoading] = useState(defaultLoading === null ? true : defaultLoading);
  const [error, setError] = useState(defaultError);

  useEffect(() => {
    setLoading(defaultLoading);
  }, [defaultLoading]);

  useEffect(() => {
    if (defaultError !== error) {
      setError(defaultError);
    }
  }, [defaultError, error]);

  return (
    <AssetPreviewWrapper className={`size-${size} ${selected ? 'selected' : ''}`}>
      {error && <div className="err">Something wrong</div>}
      {loading && <div className="loading">Loading...</div>}
      {value && (
        <div className={`img ${!loading ? 'loaded' : ''}`}>
          {value?.type === 'Image' && (
            <img
              src={value.store_path}
              alt={value.filename}
              onLoad={() => setLoading(false)}
              onError={(e) => {
                console.error(e);
                setError(e);
              }}
            />
          )}
          {value?.type === 'Video' && (
            <video width="100%" height="100%" controls>
              <source src={value.store_path} type="video/mp4" />
            </video>
          )}
          {value?.type === 'Audio' && (
            <audio controls>
              <source src={value.store_path} type="audio/mpeg" />
            </audio>
          )}
          {value?.type === 'File' && (
            <iframe src={value.store_path} width="100%" height="100%" />
          )}
        </div>
      )}
    </AssetPreviewWrapper>
  );
}

AssetPreview.propTypes = {
  size: PropTypes.oneOf(['small', 'tiny', 'xs']),
  value: PropTypes.object,
  defaultLoading: PropTypes.bool,
  defaultError: PropTypes.string,
  selected: PropTypes.bool,
};

export function AssetPreviewKey({
  hashkey,
  ...previewProps
}) {
  const { loading, error, data } = useQuery(CmsAssetResource.queries.read.query, {
    variables: { resource: { _hashkey: hashkey } },
  });

  return (
    <AssetPreview
      {...previewProps}
      defaultError={error ? 'Error fetching' : ''}
      defaultLoading={loading}
      value={data?.cms_asset_read?.item?.name ?? null}
    />
  );
}

AssetPreviewKey.propTypes = {
  hashkey: PropTypes.string,
};
