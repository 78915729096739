import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import {
  FilePlusIcon,
  ReaderIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@radix-ui/react-icons';
import { asRem } from 'lib/css';
import { ResourceSelectBasic } from 'core/ResourceSelect';
import { WebsiteResource } from 'cms/website/resource';
import { useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { StatusError, StatusLoading } from 'core/Status';
import { ResourceTypeActiveUsersList } from 'ants/ActiveUsers';
import { Tooltip } from 'core/Tooltip';
import { ReactComponent as WebsiteIcon } from 'assets/cms/icon-website.svg';
import {
  getSelectedWebsiteName, saveSelectedWebsiteName,
} from 'core/localStore';
import { UserContext } from 'core/UserContext';
import { Text16Regular } from 'style/typography';
import { WebTreeResource } from './resource';
import { WebtreeFromInt, webtreeHashKey } from './tree';

const TreeViewWrapper = styled.div`
  width: 100%;
  .empty-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: ${asRem(12)};
  }

  .wrapper-container {
    display: flex;
    align-items: center;
    gap: ${asRem(8)};
  
    > svg {
      width: ${asRem(16)};
      height: ${asRem(16)};
    }

    >div {
      width: 100%;
      button {
        ${'' /* background-color: transparent; */}
        padding: 0;
      }
    }

    .SelectTrigger {
      display: flex;
      align-items: center;
      gap: ${asRem(4)};
      justify-content: space-between;
      width: 100%;
      outline: none;
      border: none;
      color: var(--color-text-2);
      background-color: transparent;
      font-size: ${asRem(14)};
      font-weight: 400;
      padding: 0;
    }
  }
    
  .tree {
    margin: ${asRem(8)} 0;
    background: var(--color-secondary-bg);
    border-radius: ${asRem(8)};
    max-height: calc(100vh - 360px); 
    overflow-y: auto;
    padding-bottom: ${asRem(20)};
      &.overflowing {
        -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
        mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
      }
  }

    ul {
     :first-child {
        li {
          :last-child {
            border-bottom: none;
          }
        }
      }
      li {
        div {
          color: var(--color-text-2);
        }
      }
    }

  }
`;

const TreeNodesControllerWrapper = styled.div`
.empty {
  background: var(--color-secondary-bg);
  padding: ${asRem(20)};
  border-radius: ${asRem(8)};
  .primary {
    margin-top: ${asRem(20)};
    display: block;
  }
  h4 {
    display: block;
  }
}
.tree-actions {
  padding: ${asRem(10)};
  button {
    display: flex;
    align-items: center;
    gap: ${asRem(4)};
    color: var(--color-highlight-1);
    line-height: 0;
    div::last-child {
      line-height: ${asRem(15)};
    }
    &:hover {
      background: var(--color-text-rev);
    }
  }
}
`;

export function TreeView({
  showNewNodeLink = true,
  onNodeSelect,
  onNewNode,
  selectedNode,
}) {
  const userContext = useContext(UserContext);

  const treeRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('auto');
  const [isOverflowing, setIsOverflowing] = useState(false);

  const updateMaxHeight = () => {
    const viewportHeight = window.innerHeight;
    const remainingHeight = viewportHeight - 360;
    setMaxHeight(`${remainingHeight}px`);

    if (treeRef.current) {
      setIsOverflowing(treeRef.current.scrollHeight > treeRef.current.clientHeight);
    }
  };

  useEffect(() => {
    updateMaxHeight();

    window.addEventListener('resize', updateMaxHeight);
    return () => window.removeEventListener('resize', updateMaxHeight);
  }, []);

  return (
    <TreeViewWrapper>
      <ResourceSelectBasic
        resourceDef={WebsiteResource}
        autoSelectFirst
        renderIcon={() => <WebsiteIcon />}
        onSelect={(item) => {
          userContext.setSelectedWebsite(item?.item?.resource?.hashkey);
          saveSelectedWebsiteName(item?.item?.resource?.website);
        }}
        renderDefaultContent={() => (
          <div className="empty-container">
            <Text16Regular>Let&apos;s get started!</Text16Regular>
            <Link to="/cms/website/create" className="primary">Create New Website</Link>
          </div>
        )}
      />
      {(userContext?.selectedWebsite !== 'null'
        && userContext.selectedWebsite !== null
        && userContext.selectedWebsite !== 'undefined'
        && userContext.selectedWebsite !== undefined) && (
        <div
          ref={treeRef}
          className={`tree ${isOverflowing ? 'overflowing' : ''}`}
          style={{ maxHeight }}
          onScroll={updateMaxHeight}
        >
          <TreeNodesController
            website={userContext?.selectedWebsite}
            webName={getSelectedWebsiteName()}
            showNewNodeLink={showNewNodeLink}
            onNodeSelect={onNodeSelect}
            onNewNode={onNewNode}
            selectedNode={selectedNode}
          />
        </div>
      )}
    </TreeViewWrapper>
  );
}

TreeView.propTypes = {
  showNewNodeLink: PropTypes.bool,
  onNodeSelect: PropTypes.func,
  onNewNode: PropTypes.func,
  selectedNode: PropTypes.string,
};

export function resolveFullPath(node, parentPath = '/') {
  const newNode = { ...node };
  newNode.fullPath = `${parentPath}${node.path}/`;
  newNode.children = newNode.children.map((child) => resolveFullPath(child, newNode.fullPath));
  return newNode;
}

export function TreeNodesController({
  website,
  webName,
  showNewNodeLink,
  onNodeSelect,
  onNewNode,
  selectedNode,
}) {
  const [getWebsite, { loading, error, data }] = useLazyQuery(WebTreeResource.queries.nodes.query, {
    variables: {
      website: {
        _hashkey: website,
      },
    },
  });

  useEffect(() => {
    if (website !== undefined && website !== null
       && website !== 'undefined' && website !== 'null') {
      getWebsite();
    }
  }, [website]);

  let nodes = [];
  let empty = false;
  if (data && data.cms_webtree_nodes) {
    const { raw } = data.cms_webtree_nodes;
    if (raw.length === 0) {
      empty = true;
    } else {
      const itemIndexes = {};
      const items = raw.split('\n').map((item, index) => {
        const parts = item.split('#');
        if (parts.length < 3) {
          return null;
        }
        const key = parts[0];
        itemIndexes[key] = index;
        return {
          key,
          resource: WebtreeFromInt(key, webName),
          parentKey: parts[1],
          path: parts.splice(2).join('#'),
          children: [],
        };
      });

      items.forEach((item) => {
        if (!item) {
          return;
        }
        const parentIndex = itemIndexes[item.parentKey];
        if (parentIndex !== undefined) {
          items[parentIndex].children.push(item);
        } else {
          nodes.push(item);
        }
      });
      nodes = nodes.map((node) => resolveFullPath(node));
    }
  }

  return (
    <TreeNodesControllerWrapper>
      {loading && <StatusLoading message="fetching tree..." />}
      {error && <StatusError error={error} />}
      {nodes && !empty && (
        <>
          <TreeNodes
            items={nodes}
            onNodeSelect={onNodeSelect}
            onNewNode={onNewNode}
            selectedNode={selectedNode}
          />
          <div className="tree-actions">
            <button
              type="button"
              onClick={onNewNode}
              className="plain"
            >
              <div>
                <FilePlusIcon />
              </div>
              <div>
                New Page
              </div>
            </button>
          </div>
        </>
      )}
      {empty && (
        <div className="empty">
          <h4>Start by creating new page</h4>
          {showNewNodeLink && (
            <Link to="/cms/webtree/create" className="primary">Create New Page</Link>
          )}
          {!showNewNodeLink && (
            <button type="button" onClick={onNewNode} className="primary">Create New Page</button>
          )}
        </div>
      )}
    </TreeNodesControllerWrapper>
  );
}

TreeNodesController.propTypes = {
  website: PropTypes.string.isRequired,
  webName: PropTypes.string,
  showNewNodeLink: PropTypes.bool,
  onNodeSelect: PropTypes.func,
  onNewNode: PropTypes.func,
  selectedNode: PropTypes.string,
};

const TreeNodesWrapper = styled.div`
li {
  display: block;
  border-left: 1px solid var(--color-border-2);

  &.l0 {
    border: none;
  }

  >.tree-nodes {
    padding-left: ${asRem(4)};
  }

  .item {
    transition: all 300ms ease-in-out;
    background: var(--color-secondary-bg);
    padding-right: ${asRem(6)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .expand {
      width: ${asRem(20)};
      button {
        font-size: ${asRem(12)};
        line-height: 0.6;
      }
    }

    &:hover, &.active {
      background: var(--color-button-primary-bg-overlay);
      border-radius: ${asRem(6)};
    }

    .name {
      padding-right: ${asRem(6)};
      flex: 1;
      text-align: left;
      padding: ${asRem(6)};
      color: var(--color-text-1);
      display: flex;
      &:hover {
        color: var(--color-highlight-1);
      }

      >.webtree {
        margin-left: ${asRem(4)};
        padding: ${asRem(2)} ${asRem(4)};
      }
    }
    .actions {
      display: flex;
      align-items: center;
      gap: ${asRem(4)};
      a, button {
        display: block;
        padding: ${asRem(4)};
        margin-right: 0;
        font-size: ${asRem(12)};
        line-height: 0.6;
        color: var(--color-highlight-1);
      }
    }
  }
}

`;

export function TreeNodes({
  items,
  level = 0,
  onNodeSelect,
  selectedNode,
  onNewNode,
}) {
  const [expanded, setExpanded] = React.useState(level < 3);
  const actionToggleAnimations = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '80%' },
  };

  return (
    <TreeNodesWrapper className={`tree-nodes level-${level}`}>
      <ul>
        {items.map((node) => (
          <li key={node.key} className={`l${level}`}>
            <motion.div className={`item ${selectedNode === node.path ? 'active' : ''}`} whileHover="open" initial="closed" animate="closed">
              <div className="expand">
                {node.children.length > 0 && expanded && (
                  <button
                    type="button"
                    className="name clean plain"
                    onClick={() => { setExpanded(!expanded); }}
                    aria-label="Expand"
                  >
                    <ChevronDownIcon />
                  </button>
                )}
                {node.children.length > 0 && !expanded && (
                  <button
                    type="button"
                    className="name clean plain"
                    onClick={() => { setExpanded(!expanded); }}
                    aria-label="Expand"
                  >
                    <ChevronRightIcon />
                  </button>
                )}
                {node.children.length === 0 && <span style={{ width: '12px' }} />}
              </div>
              <button
                className="name clean plain"
                type="button"
                onClick={() => { onNodeSelect(node); }}
                aria-label="Preview"
              >
                <div>
                  {node.path}
                </div>
                <ResourceTypeActiveUsersList
                  resourceType={WebTreeResource.resourceId}
                  resourceKey={webtreeHashKey(node.resource)}
                  ignoreActions={['read']}
                  size={16}
                />
              </button>
              <motion.div
                className="actions"
                variants={actionToggleAnimations}
              >
                <Tooltip
                  text="New sub page"
                  renderTrigger={() => (
                    <button
                      className="clear plain compact"
                      type="button"
                      onClick={() => { onNewNode(node); }}
                      aria-label="Preview"
                    >
                      <FilePlusIcon />
                    </button>
                  )}
                />
                <Tooltip
                  text="Preview & Edit"
                  renderTrigger={() => (
                    <button
                      className="clear plain compact"
                      type="button"
                      onClick={() => { onNodeSelect(node); }}
                      aria-label="Preview"
                    >
                      <ReaderIcon />
                    </button>
                  )}
                />
              </motion.div>
            </motion.div>
            <AnimatePresence>
              {node.children.length > 0 && expanded && (
                <motion.div
                  className="tree-nodes"
                  initial={{ y: '20px', opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: '-20px', opacity: 0 }}
                >
                  <TreeNodes
                    items={node.children}
                    level={level + 1}
                    onNodeSelect={onNodeSelect}
                    onNewNode={onNewNode}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </li>
        ))}
      </ul>
    </TreeNodesWrapper>
  );
}

const TreeNodeProps = PropTypes.shape({
  key: PropTypes.string,
  path: PropTypes.string,
  parentKey: PropTypes.string,
});
TreeNodeProps.children = PropTypes.arrayOf(TreeNodeProps);

TreeNodes.propTypes = {
  items: PropTypes.arrayOf(TreeNodeProps),
  level: PropTypes.number,
  onNodeSelect: PropTypes.func,
  onNewNode: PropTypes.func,
  selectedNode: PropTypes.string,
};
