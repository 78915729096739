import { gql } from '@apollo/client';
import { VersionHistoryFields } from 'ants/query';
import { ListEditorFieldMetaFields, ListEditorFieldMetaFieldsV2 } from 'core/query';

export const CmsPageCreateHintQuery = gql`
query CmsPageCreateHintQuery($name: String!, $webtree_parent: WebTreeInput, $website: WebsiteInput) {
  cms_page_create_hint(name: $name, webtree_parent: $webtree_parent, website: $website) {
    name
    path
    similar_items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      page {
        name
        internal_name
      }
    }
    key_suggestions
  }
}
`;

export const CmsPageCreateMutation = gql`
mutation CmsPageCreate(
  $page: CmsPagePageInput!,
  $website: WebsiteInput,
  $parent: WebTreeInput,
  $path: String,
  $key: String,
) {
  cms_page_create(
    page: $page,
    website: $website,
    parent: $parent,
    path: $path,
    key: $key,
  ) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
      webtree { key, hashkey: _hashkey }
      key_suggestions
  }
}
`;

export const CmsFullPageCreateMutation = gql`
mutation CmsFullPageCreate(
  $page: CmsPagePageInput!,
  $website: WebsiteInput,
  $parent: WebTreeInput,
  $path: String,
  $key: String,
  $page_props: WebPagePropsInput,
  $body: CmsPageBodyInput,
  $editor_meta: CmsPageEditorMetaInput,
  $tree_props: WebTreePropsInput
  $webtree: WebTreeInput,
) {
  cms_page_create(
    page: $page,
    website: $website,
    parent: $parent,
    path: $path,
    page_props: $page_props,
    body: $body,
    editor_meta: $editor_meta,
    tree_props: $tree_props,
    webtree: $webtree,
    key: $key,
  ) {
      status {
        okay
        errors {
          msg
          field_path
          code
        }
        key
      }
      webtree { key, hashkey: _hashkey }
      key_suggestions
  }
}
`;

export const ContentSourceDefFields = `
  typ
  structure { key, hashkey: _hashkey }
  action { key, default_vals }
  url {
    url
    method
    headers { key value }
    is_form_post
    body
    body_raw
    params{
      key
      store_type
      name
      default_val
      options { key value }
      required
      location
    }
  }
  include_with_parent
`;
export const ContentSourceDefFieldsV2 = `
  typ
  structure { key, _hashkey }
  action { key, default_vals }
  url {
    url
    method
    headers { key value }
    is_form_post
    body
    body_raw
    params{
      key
      store_type
      name
      default_val
      options { key value }
      required
      location
    }
  }
  include_with_parent
`;
export const ContentSourceValueFields = `
  typ
  structure { key, hashkey: _hashkey }
  action_key
  src_structure { key, hashkey: _hashkey }
  include_with_parent
  value {
    structure {
      content_key { key, structure, hashkey: _hashkey }
      data
    }
    action { params }
    url { params { key value }}
  }
  resolved {
    action {
      action_key
      field_path
      data
      is_error
      error_msg
    }
    url { data }
  }
`;

export const ContentSourceValueFieldsV2 = `
  typ
  structure { key, _hashkey }
  action_key
  src_structure { key, _hashkey }
  include_with_parent
  value {
    structure {
      content_key { key, structure, _hashkey }
      data
    }
    action { params }
    url { params { key value }}
  }
  resolved {
    action {
      action_key
      field_path
      data
      is_error
      error_msg
    }
    url { data }
  }
`;

export const CmsPageReadQuery = gql`
fragment DivData on ContentDiv {
  raw
  raw_type
  data
  source_type

  source_def {
    ${ContentSourceDefFields}
  }
  source_val {
    ${ContentSourceValueFields}
  }

  tag_name
  class_name
  key
  title

  attrs { key value }
}
query CmsPageReadQuery($resource: CmsPageInput!, $withHistory: Boolean) {
  cms_page_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        hashkey: _hashkey
      }
      page {
        name
        internal_name
        base_page { hashkey: _hashkey }
        custom_config
      }
      editor_meta {
        content {
          ${ListEditorFieldMetaFields}
        }
      }
      body {
        body_content_type
        source_def {
          ${ContentSourceDefFields}
        }
        source_val {
          ${ContentSourceValueFields}
        }

        content {
          ...DivData
          # https://github.com/graphql/graphql-spec/issues/91
          # Level 1
          children {
            ...DivData

            # Level 2
            children {
              ...DivData

              # Level 3
              children {
                ...DivData

                # Level 4
                children {
                  ...DivData

                  # Level 5
                  children {
                    ...DivData
                  }
                }
              }
            }
          }
        }
      }
      web {
        locations { account, website, key, hashkey: _hashkey }
      }
    }
    history {
      ${VersionHistoryFields}
    }
  }
}
`;

export const CmsPageUpdateMutation = gql`
mutation CmsPageUpdateMutation(
  $resource: CmsPageInput!,
  $page: CmsPagePageInput,
  $body: CmsPageBodyInput,
  $editor_meta: CmsPageEditorMetaInput,
  $version: RecordStage
) {
  cms_page_update(
    resource: $resource,
    page: $page,
    body: $body,
    editor_meta: $editor_meta,
    version: $version,
  ) {
    status {
      okay
      errors {
        msg
        field_path
        code
      }
      key
    }
  }
}
`;

export const CmsPageDeleteMutation = gql`
mutation  CmsPageDeleteMutation(
  $resource: CmsPageInput!,
  $name: String!) {
  cms_page_delete(resource: $resource, name: $name) {
    status {
      okay
      errors { msg, field_path, code}
      key
    }
  }
}
`;

export const CmsPageSearchQuery = gql`
query CmsPageSearchQuery($search: String!, $page: PagedRequestInput) {
  cms_page_search(search: $search, page: $page) {
    items {
      resource {
        account
        key
        hashkey: _hashkey
      }
      page {
        name
        internal_name
        base_page { hashkey: _hashkey }
      }
    }
    page {
      next_page_key
      count
      prev_page_key
    }
  }
}
`;

export const CmsPageNameQuery = gql`
query CmsPageNameQuery($resource: CmsPageInput!) {
  cms_page_name(resource: $resource) {
    name
    internal_name
    slug
    parent { hashkey: _hashkey }
  }
}
`;

export const CmsPageReadV2Query = gql`
fragment DivData on ContentDiv {
  raw
  raw_type
  data
  source_type

  source_def {
    ${ContentSourceDefFieldsV2}
  }
  source_val {
    ${ContentSourceValueFieldsV2}
  }

  tag_name
  class_name
  key
  title

  attrs { key value }
}
query CmsPageReadV2Query($resource: CmsPageInput!, $withHistory: Boolean) {
  cms_page_read(resource: $resource, with_history: $withHistory) {
    item {
      resource {
        account
        key
        _hashkey
      }
      page {
        name
        internal_name
        base_page { _hashkey }
        custom_config
      }
      editor_meta {
        content {
          ${ListEditorFieldMetaFieldsV2}
        }
      }
      body {
        body_content_type
        source_def {
          ${ContentSourceDefFieldsV2}
        }
        source_val {
          ${ContentSourceValueFieldsV2}
        }

        content {
          ...DivData
          # https://github.com/graphql/graphql-spec/issues/91
          # Level 1
          children {
            ...DivData

            # Level 2
            children {
              ...DivData

              # Level 3
              children {
                ...DivData

                # Level 4
                children {
                  ...DivData

                  # Level 5
                  children {
                    ...DivData
                  }
                }
              }
            }
          }
        }
      }
      web {
        locations { account, website, key, _hashkey }
      }
    }
  }
}
`;
