import React, {
  lazy,
  useContext,
  Suspense,
  useState,
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { asRem, Breakpoint } from 'lib/css';
import { Text14Bold, Label10Medium } from 'style/typography';
import { ReactComponent as IconLogo } from 'assets/icons/icon-rz-logo.svg';
import { ReactComponent as BurgerMenu } from 'assets/icons/icon-menu.svg';
import { SportsDropdown } from 'core/SportsDropdown';
import { UserDropdown } from 'core/UserDropdown';
import { AvailableSports } from 'modal';
import { FantasyMenu } from 'Fantasy/Menu';
import { useLazyQuery } from '@apollo/client';
import { SportsAccountGet } from 'account/query';
import { Config } from 'config';

import { MenuManagement } from 'cms/MenuManagement';
import { ScreenPopover } from './ScreenPopover';
import { getToken, getAccountKey, saveRoanuzMode } from './localStore';
import { UserContext } from './UserContext';
import { AppContext } from './AppContext';

const CricketMenu = lazy(() => import('sports/cricket/Menu'));
const FootballMenu = lazy(() => import('sports/football/Menu'));
const EcomMenu = lazy(() => import('ecom/Menu'));
const CmsMenu = lazy(() => import('cms/Menu'));

const SideBarWrapper = styled.div`
  background: var(--color-secondary-bg);
  height: 100%;
  border-radius: ${asRem(16)};
  border: 1px solid var(-color-border-1);
  transition: width 0.3s linear;

  &.collapsible {
    width: ${asRem(66)};

    .sidebar-inner-wrapper { 
      padding: ${asRem(20)} ${asRem(10)};
    }

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bottom-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.expanded {
    width: ${asRem(300)};

    .sidebar-inner-wrapper {
      padding: ${asRem(28)} ${asRem(20)};
    }

    .bottom-container {
      button {
        margin-top: ${asRem(6)};
      }
    }
  }

  .sidebar-inner-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    border-radius: ${asRem(16)};
    overflow-y: auto;
  }

  .logo-container {
    .logo-header {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: ${asRem(12)};
      padding: 0 0 ${asRem(28)};
      color: var(--color-text-1);
    }
  }


  .burger-menu-container {
    display: flex;
    @media screen and (min-width: ${Breakpoint.sm}) {
      display: none;
    }
  }
  

  .mobile-view-menu {
    flex-direction: column;
  }
`;

const MobileMenuWrapper = styled.div`
  .menu-bar-container {
    flex-direction: column;
    align-items: start;
  }
`;

export function Sidebar() {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const [showPopup, setShowPopup] = useState(false);
  const token = getToken();
  let secondMenu = null;

  const className = `active-layout-${appContext.viewState.layoutWidth} ${appContext.menuLayout} box`;

  const [getConfig, {
    data: ConfigData,
  }] = useLazyQuery(SportsAccountGet);

  useEffect(() => {
    if (!userContext.roanuzMode) {
      getConfig({
        variables: { account: { key: getAccountKey() } },
      }).then((resp) => {
        if (resp
          && resp?.data?.ants_acc_sports_account_get?.sports_account?.config?.is_roanuz_mode) {
          userContext.setRoanuzMode(true);
          saveRoanuzMode(true);
        }
      });
    }
  }, [ConfigData, getConfig, userContext]);

  if (token) {
    switch (userContext.sport) {
      case AvailableSports.Cricket.key:
        secondMenu = (<CricketMenu />);
        break;
      case AvailableSports.Football.key:
        secondMenu = (<FootballMenu />);
        break;
      case AvailableSports.FantasyEditor.key:
        secondMenu = (<FantasyMenu />);
        break;
      case AvailableSports.Ecom.key:
        secondMenu = (<EcomMenu />);
        break;
      case AvailableSports.Cms.key:
        secondMenu = (<CmsMenu />);
        break;
      default:
        break;
    }
  }

  return (
    <SideBarWrapper
      className={`${className} ${appContext.menuLayout === 'CmsMenuFullView' ? 'expanded' : 'collapsible'}`}
    >
      <div className="sidebar-inner-wrapper">
        <div className="top-container">
          <div className="logo-container">
            <div className="burger-menu-container">
              <BurgerMenu onClick={() => setShowPopup(true)} />
            </div>
            <Link className="logo-header" to="/">
              <IconLogo className="logo" />
              {appContext.menuLayout === 'CmsMenuFullView' && (
                <div>
                  <Text14Bold as="span">Roanuz Ants</Text14Bold>
                  {Config.showSandbox && (
                    <Label10Medium className="label">Sandbox</Label10Medium>
                  )}
                  {getAccountKey() === Config.rzAccountKey && (
                    <Label10Medium className="label live">Cricket API Live Data</Label10Medium>
                  )}
                </div>
              )}
            </Link>
          </div>
          <MenuManagement viewMode={appContext.menuLayout === 'CmsMenuFullView' ? 'expanded' : 'collapsible'} />
        </div>
        <div className="bottom-container">
          {userContext.account && (appContext.menuLayout === 'CmsMenuFullView') && (
            <SportsDropdown user={userContext} />
          )}
          {userContext.user && userContext.user.profile && (
            <UserDropdown user={userContext} viewMode={appContext.menuLayout === 'CmsMenuFullView' ? 'expanded' : 'collapsible'} />
          )}
        </div>
      </div>
      {showPopup && (
        <div>
          <ScreenPopover
            onClose={() => { setShowPopup(false); }}
          >
            <MobileMenuWrapper>
              <Suspense>
                {secondMenu}
              </Suspense>
            </MobileMenuWrapper>
          </ScreenPopover>
        </div>
      )}
    </SideBarWrapper>
  );
}

Sidebar.propTypes = {
};
