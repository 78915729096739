/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import * as Yup from 'yup';
import { arrayAsObject, objectAsArray } from 'lib/utils';
import {
  ColumnSet, MutationAction, QueryAction, RefField, Resource,
  StringFieldMinMax,
} from 'ants/resource';
import { isDeletedValue } from 'core/Form';
import {
  MatchCreateMutation,
  MatchReadQuery,
  MatchSearchQuery,
  MatchHeadlessSearchQuery,
  MatchUpdateMutation,
  MatchUpdateRulesMutation,
  MatchUpdateTossMutation,
  MatchUpdateSquadMutation,
  MatchUpdatePlayMutation,
  MatchUpdateInningsMutation,
  MatchUpdateReviewMutation,
  MatchUpdateResultMutation,
  MatchUpdateRewardMutation,
  MatchDataUpdateMutation,
  MatchUpdateUmpireMutation,
  MatchTopCountsQuery,
  MatchNameQuery,
  MatchUpdateSessionMutation,
} from './query';
import { MatchImageView } from './ResourceImage';

function matchSquadValidator() {
  const validateSize = (value, context) => {
    const { rules } = context.options.context.context.queryResponse;
    const squad = objectAsArray(value);
    if (rules.validate_players_count_per_team && !rules.allow_induvidual_team_squad_update) {
      if ((rules.player_per_team < squad.length) || (squad.length === rules.player_per_team)) {
        const slicedValue = typeof value === 'object'
          ? Object.values(value).slice(0, rules.player_per_team)
          : value.slice(0, rules.player_per_team);
        const filteredValue = slicedValue.filter((val) => !isDeletedValue(val));
        if (filteredValue.length === rules.player_per_team) {
          return true;
        }
        return false;
      }
      return false;
    }
    if (rules.allow_induvidual_team_squad_update && rules.validate_players_count_per_team) {
      const initVal = context.options.context.initialValues;
      if ((Object.values(initVal?.team_a_squad?.players)?.length === rules.player_per_team
        || Object.values(initVal?.team_b_squad?.players)?.length === rules.player_per_team)
        || (Object.values(initVal?.team_a_squad?.players)?.length > rules.player_per_team
        || Object.values(initVal?.team_b_squad?.players)?.length > rules.player_per_team)) {
        const slicedValue = typeof value === 'object'
          ? Object.values(value).slice(0, rules.player_per_team)
          : value.slice(0, rules.player_per_team);
        const filteredValue = slicedValue.filter((val) => (!isDeletedValue(val) && (val !== '')));
        if ((filteredValue.length === rules.player_per_team)
          || ((Object.values(initVal?.team_a_squad?.players)?.length === 0
          || Object.values(initVal?.team_b_squad?.players)?.length === 0))
          || filteredValue.length === 0) {
          return true;
        }
        return false;
      }
      return false;
    }
    return true;
  };

  const validatePlayer = (value, context) => {
    const { rules } = context.options.context.context.queryResponse;
    if (rules.validate_players_count_per_team) {
      const squad = objectAsArray(value);
      return squad.filter((x) => !x).length === 0;
    }
    return [];
  };

  const validatePlayerLabel = (value) => {
    const squad = objectAsArray(value.value);
    for (let i = 0; i < squad.length; i += 1) {
      if (!squad[i]) {
        return `Invalid player at #${i + 1}`;
      }
    }
    return `Invalidate player ${value.label ?? value.path}`;
  };

  const validateDuplicate = (value) => {
    const squad = objectAsArray(value.value).filter((val) => !isDeletedValue(val));
    const maps = {};
    for (let i = 0; i < squad.length; i += 1) {
      const val = squad[i];
      if (maps[val] === undefined) {
        maps[val] = i;
      } else {
        return false;
      }
    }
    return true;
  };

  const validateDuplicateLabel = (value) => {
    const squad = objectAsArray(value.value).filter((val) => !isDeletedValue(val));
    const maps = {};
    for (let i = 0; i < squad.length; i += 1) {
      const val = squad[i];
      if (maps[val] === undefined) {
        maps[val] = i;
      } else {
        return `Duplicate player found at #${maps[val] + 1} and #${i + 1}`;
      }
    }
    return `Duplicate player found in ${value.label ?? value.path}`;
  };

  const allowSamePlayersInBothTeams = (_, context) => {
    const { rules } = context.options.context.context.queryResponse;
    const { team_a_squad, team_b_squad } = context.options.context.initialValues;
    const teamAPlayers = objectAsArray(team_a_squad.players);
    const teamBPlayers = objectAsArray(team_b_squad.players);
    if (!rules.allow_same_player_in_both_teams) {
      if (teamAPlayers.some((player) => (!isDeletedValue(player) && player !== ''
       && teamBPlayers.includes(player)))) {
        return false;
      }
    }
    return true;
  };

  const validateInSquadPlayers = (value, context) => {
    const teamPath = context.path.split('.')[0];
    const squadMembers = new Set();
    context?.options?.context?.context?.relatedItem?.[teamPath]?.squad?.squad?.forEach((players) => {
      if (players.state === 'Published') {
        squadMembers.add(players.player.hashkey);
      }
    });

    const activePlayers = objectAsArray(value).filter((player) => !isDeletedValue(player));
    for (let i = 0; i < activePlayers.length; i += 1) {
      if (activePlayers[i] !== '' && !squadMembers.has(activePlayers[i])) {
        return context.createError({ message: `Player at index ${i + 1}  is not in ${teamPath.split('_').join(' ')}` });
      }
    }

    return true;
  };

  return Yup.mixed()
    .test(
      'match-squad-size',
      'Not enough player',
      validateSize,
    )
    .test(
      'match-squad-player',
      validatePlayerLabel,
      validatePlayer,
    )
    .test(
      'match-squad-duplicate',
      validateDuplicateLabel,
      validateDuplicate,
    )
    .test(
      'match-squad-player-check',
      'Common Player found in both squad',
      allowSamePlayersInBothTeams,
    )
    .test(
      'match-in-squad-player-check',
      validateInSquadPlayers,
    );
}

function requiredInSquadValidator(label) {
  const validate = (value, context) => {
    const { rules } = context.options.context.context.queryResponse;
    const { initialValues } = context.options.context;
    if (!rules.is_captain_wicketkeeper_required) {
      return true;
    }
    if (!rules.allow_induvidual_team_squad_update) {
      if (!value) {
        return false;
      }
      return true;
    }
    if (Object.values(initialValues?.team_a_squad?.players)?.length === 0
      || Object.values(initialValues?.team_b_squad?.players)?.length === 0) {
      return true;
    } if (Object.values(initialValues?.team_a_squad?.players)?.length !== 0
    && Object.values(initialValues?.team_b_squad?.players)?.length !== 0) {
      if (!value) {
        return false;
      }
      return true;
    }
    return true;
  };

  const checkInSquad = (value, context) => {
    const squad = objectAsArray(context.parent.players);
    if (value) {
      if (squad.indexOf(value) > -1) {
        return true;
      }
      return false;
    }
    return true;
  };

  return Yup.mixed()
    .test(
      'match-squad-req',
      `${label} is required`,
      validate,
    )
    .test(
      'match-squad-check',
      `${label} must be a part of the squad`,
      checkInSquad,
    );
}

const sessionValidator = () => {
  const validateSession = (values, context) => {
    const sessions = objectAsArray(values);
    for (let i = 0; i < sessions.length; i += 1) {
      const sessionKeys = Object.entries(sessions[i]).filter(([key, value]) => key !== 'last_over_str' && !value);
      if (sessionKeys.length > 0) {
        return context.createError({ message: `Invalid value at Row ${i + 1}` });
      }
    }

    return true;
  };

  return Yup.mixed().test(
    'session-validator',
    validateSession,

  );
};

const supersubValidator = () => {
  function validatesupersubplayer(value, context) {
    const squadPlayers = objectAsArray(context?.parent?.players);
    const supersubplayers = objectAsArray(value).filter((player) => !isDeletedValue(player));

    for (let i = 0; i < supersubplayers.length; i += 1) {
      if (supersubplayers[i] === '' || supersubplayers === null) {
        return context.createError({ message: `Invalid SuperSub player value at Index ${i + 1}` });
      }
      if (squadPlayers.includes(supersubplayers[i])) {
        return context.createError({ message: `SuperSub player at Index ${i + 1} is in Playin 11` });
      }
    }
    return true;
  }

  function removeDuplicateSuperSubPlayer(value, context) {
    const superSubPlayers = objectAsArray(value).filter((player) => !isDeletedValue(player) && player !== '');
    const squadName = context.path.includes('team_a_squad') ? '1st Squad' : '2nd Squad';
    const set = new Set(superSubPlayers);
    if (set.size !== superSubPlayers.length) {
      return context.createError({ message: `Duplicate superSub players found in ${squadName}` });
    }
    return true;
  }

  return Yup.mixed()
    .test(
      'super-sub-validator',
      validatesupersubplayer,
    ).test(
      'super-sub-duplicate-validator',
      removeDuplicateSuperSubPlayer,
    );
};

function squadCS(name, viewKey) {
  return ColumnSet({
    name,
    shape: Yup.object().shape({
      // players: Yup.array().of(Yup.string().required().min(1)).required(),
      players: matchSquadValidator(),
      captain: requiredInSquadValidator('Captain'),
      keeper: requiredInSquadValidator('Keeper'),
      supersub: supersubValidator(),
    }),
    viewKey,
  });
}

function prepareForSave(self, resource, data, item) {
  const resp = self.defaultPrepareForSave(self, resource, data);
  return {
    ...resp,
    match_name: item.item.match.internal_name,
  };
}

function validateOversPerInnings() {
  const validate = (value, context) => {
    if (!value && context.parent.format !== 'Test') {
      return false;
    }
    return true;
  };

  return Yup.mixed()
    .test(
      'match-validate-innings',
      'Overs per innings is required',
      validate,
    );
}

export const MatchResource = Resource({
  resourceId: 'match',
  name: 'Match',
  storeId: 'Match',
  pageKey: 'sports.cricket.match',
  sportScope: true,
  columnSets: [
    ColumnSet({
      name: 'Match',
      shape: Yup.object().shape({
        name: StringFieldMinMax(3, 120),
        internal_name: StringFieldMinMax(3, 120),
        short_name: StringFieldMinMax(2, 120),
        start_at: Yup.number().required(),
        format: Yup.string().required(),
        status: Yup.string().required(),
        sub_title: Yup.string().nullable(),
        stadium: RefField(),
        tournament: RefField(),
      }),
      referenceFields: ['stadium', 'tournament'],
    }),
    ColumnSet({
      name: 'Teams',
      shape: Yup.object().shape({
        team_a: RefField(),
        team_b: RefField(),
      }),
      referenceFields: ['team_a', 'team_b'],
    }),
    ColumnSet({
      name: 'Rules',
      shape: Yup.object().shape({
        format: Yup.string().required(),
        mg: Yup.string().required(),
        overs_per_innings: validateOversPerInnings(),
        drs_per_innings: Yup.number().nullable(),
        balls_per_over: Yup.number().nullable(),
        player_per_team: Yup.number().nullable(),
        bench_per_team: Yup.number().nullable(),
        bowler_per_innings: Yup.number().nullable(),
        batsman_per_innings: Yup.number().nullable(),
        allow_induvidual_team_squad_update: Yup.boolean().nullable(),
        validate_players_count_per_team: Yup.boolean().nullable(),
        enable_ball_of_dismissed_stats: Yup.boolean().nullable(),
        allow_same_player_in_both_teams: Yup.boolean().nullable(),
        allow_squad_update_without_toss: Yup.boolean().nullable(),
        is_captain_wicketkeeper_required: Yup.boolean().nullable(),
        runs_per_no_ball: Yup.number().nullable(),
        runs_per_wide: Yup.number().nullable(),
      }),
    }),
    ColumnSet({
      name: 'Toss',
      shape: Yup.object().shape({
        winner: Yup.string().nullable().default(),
        elected: Yup.string().nullable().default(),
        squad_confirmed: Yup.boolean().nullable(),
        called: Yup.string().nullable(),
      }),
    }),
    ColumnSet({
      name: 'Umpires',
      shape: Yup.object().shape({
        tv_umpires: Yup.mixed().nullable(),
        match_umpires: Yup.mixed().nullable(),
        reserve_umpires: Yup.mixed().nullable(),
        match_refree: Yup.mixed().nullable(),
      }),
    }),
    ColumnSet({
      name: 'Innings',
      shape: Yup.object().shape({
        innings: Yup.mixed().nullable(),
      }),
    }),
    ColumnSet({
      name: 'Sessions',
      shape: Yup.object().shape({
        sessions: sessionValidator(),
      }),
    }),
    squadCS('TeamASquad', 'team_a_squad'),
    squadCS('TeamBSquad', 'team_b_squad'),
    ColumnSet({
      name: 'Play',
      shape: Yup.object().shape({
        play_status: Yup.string().required(),
        break_type: Yup.string().nullable().default(),
        break_seconds: Yup.number().nullable().default(),
        play_resume_time: Yup.number().nullable(),
        break_note: Yup.string().nullable().default(),
        internal_notes: Yup.string().default('').nullable(),
        public_msg: Yup.string().default('').nullable(),
        day: Yup.number().default(1),
        expected_start_time: Yup.number().nullable(),
        pitch_favours: Yup.string().nullable(),
        pitch_favours_bowling: Yup.string().nullable(),
      }),
    }),
    ColumnSet({
      name: 'ReviewChecklist',
      shape: Yup.object().shape({
        pre_match_review: Yup.boolean().nullable(),
        pre_match_validated: Yup.boolean().nullable(),
        post_match_review: Yup.boolean().nullable(),
        post_match_validated: Yup.boolean().nullable(),
      }),
      viewKey: 'review_checklist',
    }),
    ColumnSet({
      name: 'Data',
      shape: Yup.object().shape({
        tags: Yup.mixed().nullable().default(),
        properties: Yup.mixed().nullable().default(),
      }),
    }),
    ColumnSet({
      name: 'Result',
      shape: Yup.object().shape({
        winner: Yup.string().nullable(),
        typ: Yup.string().nullable().default(),
        dl_applied: Yup.boolean().nullable(),
        win_by: Yup.number().nullable(),
      }),
    }),
    ColumnSet({
      name: 'Reward',
      shape: Yup.object().shape({
        pom: Yup.mixed().nullable().default(),
        team_a_nrr: Yup.string().nullable().default(),
        team_a_points: Yup.string().nullable().default(),
        team_b_nrr: Yup.string().nullable().default(),
        team_b_points: Yup.string().nullable().default(),
      }),
    }),
    ColumnSet({
      name: 'TournamentDetails',
      shape: Yup.object().shape({
        round_key: Yup.string().required(),
        group_key: Yup.string().required(),
      }),
      viewKey: 'tournament_details',
    }),
    ColumnSet({
      name: 'Status',
      shape: Yup.object().shape({
        published: Yup.boolean().default(false),
        notes: Yup.string().max(1024, 'Too big').nullable().default(''),
      }),
    }),
  ],

  listPrimaryActions: [
    { action: 'update', page: 'update.match' },
  ],
  imageRender: (item) => (<MatchImageView item={item} />),
  queries: {
    read: QueryAction({
      query: MatchReadQuery,
      responsePath: 'sports_cricket_match_read',
      resourceNamePath: 'item.match.internal_name',
    }),
    list: QueryAction({ query: MatchSearchQuery, responsePath: 'sports_cricket_match_search' }),
    search: QueryAction({
      query: MatchSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'match.internal_name',
    }),
    counts: QueryAction({
      query: MatchTopCountsQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'match.internal_name',
      responsePath: 'sports_cricket_match_search.top_counts',
    }),
    name: QueryAction({
      query: MatchNameQuery,
      responsePath: 'sports_cricket_match_name',
      resourceNamePath: 'sports_cricket_match_name.name',
    }),
    headlessSearch: QueryAction({
      query: MatchHeadlessSearchQuery,
      resourcePath: 'resource.hashkey',
      resourceNamePath: 'match.internal_name',
      responsePath: 'sports_cricket_match_review_search',
    }),
  },
  mutations: {
    update: MutationAction({
      mutation: MatchUpdateMutation,
      cs: ['match', 'teams', 'status', 'tournament_details'],
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.tournamentDetails = data.tournament_details;
        return {
          ...resp,
        };
      },
    }),
    updateToss: MutationAction({ mutation: MatchUpdateTossMutation, cs: ['toss'], prepareForSave }),
    updateUmpire: MutationAction({
      mutation: MatchUpdateUmpireMutation,
      cs: ['umpires'],
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.umpires.tv_umpires = objectAsArray(resp?.umpires?.tv_umpires) || [];
        resp.umpires.match_umpires = objectAsArray(resp?.umpires?.match_umpires) || [];
        resp.umpires.reserve_umpires = objectAsArray(resp?.umpires?.reserve_umpires) || [];
        resp.umpires.match_referee = objectAsArray(resp?.umpires?.match_referee) || [];
        return resp;
      },
    }),
    updateSquad: MutationAction({
      mutation: MatchUpdateSquadMutation,
      responsePath: 'sports_cricket_match_update_squad',
      cs: ['team_a_squad', 'team_b_squad'],
      // prepareForEdit(self, resource, item) {
      //   const prepared = self.defaultPrepareForEdit(self, resource, item);
      //   prepared.team_a_squad.players = arrayAsObject(prepared.team_a_squad.players);
      //   prepared.team_b_squad.players = arrayAsObject(prepared.team_b_squad.players);
      //   return prepared;
      // },
      prepareForSave(self, resource, data, item) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.team_a_squad.players = objectAsArray(resp.team_a_squad.players, { defaultValue: '', null: '' }).filter((player) => player !== '');
        resp.team_b_squad.players = objectAsArray(resp.team_b_squad.players, { defaultValue: '', null: '' }).filter((player) => player !== '');
        resp.team_a_squad.bench = objectAsArray(resp.team_a_squad.bench).filter((player) => player && player !== '');
        resp.team_b_squad.bench = objectAsArray(resp.team_b_squad.bench).filter((player) => player && player !== '');
        resp.team_a_squad.supersub = objectAsArray(resp.team_a_squad.supersub);
        resp.team_b_squad.supersub = objectAsArray(resp.team_b_squad.supersub);
        return {
          ...resp,
          match_name: item.item.match.internal_name,
        };
      },
    }),
    updatePlay: MutationAction({
      mutation: MatchUpdatePlayMutation,
      cs: ['play'],
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.play.break_type = resp?.play?.break_type !== '' ? resp?.play?.break_type : null;
        delete resp.play.play_updated_at;
        return {
          ...resp,
        };
      },
    }),
    updateInnings: MutationAction({ mutation: MatchUpdateInningsMutation, cs: ['innings'], prepareForSave }),
    updateRules: MutationAction({
      mutation: MatchUpdateRulesMutation,
      cs: ['rules'],
      prepareForSave,
    }),
    updateResult: MutationAction({
      mutation: MatchUpdateResultMutation,
      cs: ['result'],
      responsePath: 'sports_cricket_match_update_result',
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.result.winner = resp.result.winner === '' ? null : resp.result.winner;
        resp.result.typ = resp.result.typ === '' ? null : resp.result.typ;
        return {
          ...resp,
        };
      },
    }),
    updateReward: MutationAction({
      mutation: MatchUpdateRewardMutation,
      cs: ['reward'],
      responsePath: 'sports_cricket_match_update_reward',
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        if (resp && resp.reward && resp.reward.pom) {
          resp.reward.pom = objectAsArray(resp.reward.pom);
        }
        resp.reward.team_a_nrr = resp?.reward?.team_a_nrr ? parseFloat(resp?.reward?.team_a_nrr) : 0.0;
        resp.reward.team_a_points = resp?.reward?.team_a_points ? parseFloat(resp?.reward?.team_a_points) : 0.0;
        resp.reward.team_b_nrr = resp?.reward?.team_b_nrr ? parseFloat(resp?.reward?.team_b_nrr) : 0.0;
        resp.reward.team_b_points = resp.reward?.team_b_points ? parseFloat(resp.reward?.team_b_points) : 0.0;
        return {
          ...resp,
        };
      },
    }),
    updateReview: MutationAction({
      mutation: MatchUpdateReviewMutation,
      cs: ['review_checklist'],
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        const postVariables = {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: data._hashkey,
          },
          review: resp.review_checklist,
        };
        return postVariables;
      },
    }),
    updateAdvancedSettings: MutationAction({
      responsePath: 'sports_cricket_data_update_match',
      mutation: MatchDataUpdateMutation,
      cs: ['data'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.data.tags = arrayAsObject(
          resp.data.tags,
        ) ?? {};
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.data.tags = objectAsArray(resp.data.tags);
        const postVariables = {
          resource: {
            // eslint-disable-next-line no-underscore-dangle
            _hashkey: data._hashkey,
          },
          tags: resp.data.tags,
          properties: resp.data.properties,
        };
        return postVariables;
      },
    }),
    updateSession: MutationAction({
      responsePath: 'sports_cricket_match_update_sessions',
      mutation: MatchUpdateSessionMutation,
      cs: ['sessions'],
      prepareForEdit(self, resource, data) {
        const resp = self.defaultPrepareForEdit(self, resource, data);
        resp.sessions.sessions = arrayAsObject(resp.sessions.sessions);
        return resp;
      },
      prepareForSave(self, resource, data) {
        const resp = self.defaultPrepareForSave(self, resource, data);
        resp.sessions.sessions = objectAsArray(resp.sessions.sessions);
        resp.sessions.sessions = resp.sessions.sessions.map((session) => ({
          ...session,
          day: parseInt(session?.day, 10),
          session: parseInt(session?.session, 10),
          innings: parseInt(session?.innings, 10),
          session_start_day_remaining_overs_est: `${Number(session?.session_start_day_remaining_overs_est).toFixed(1)}`,
        }));
        return resp;
      },
    }),
    create: MutationAction({ mutation: MatchCreateMutation }),
  },
});
