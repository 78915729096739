import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem, Breakpoint } from 'lib/css';
import { useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';
import { AppContext } from './AppContext';

const AppLayoutWrapper = styled.div`
  height: 100%;
  min-height: 100%;

  &.secondary-on {
    --layout-nav-height: var(--layout-nav-sec-height);

    .rz-breadcrumb {
      display: none;
    }
  }

  >.top {
    border-bottom: ${asRem(1)} solid var(--color-table-border);
    background: var(--color-primary-bg);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  >.bottom {
    height: var(--layout-bottom-height);
    ${'' /* position: absolute;
    left: 0;
    right: 0;
    bottom: 0; */}
    padding: 0 ${asRem(10)}; 
    @media screen and (min-width: ${Breakpoint.sm}) {
      padding: 0 ${asRem(28)};
    }
  }

  >.content{
    display: flex;
    >.content-left, >.content-right {
      overflow: auto;
      position: fixed;
      /* top: 0;
      bottom: 0; */
      bottom: 0;
      top: var(--layout-nav-height);
      padding-bottom: var(--layout-bottom-height);
    }

    >.content-left {
      width: var(--layout-left-width);
      background: var(--color-primary-bg);
      border-right: ${asRem(1)} solid var(--color-table-border);
      left: 0;

    }

    >.content-right {
      width: var(--layout-right-width);
      right: 0;
    }

    >.content-container {
      width: 100%;
      max-width: var(--layout-container-width-1);
      margin: auto;
      padding: var(--layout-nav-height) var(--layout-right-width) var(--layout-bottom-height) var(--layout-left-width);
      background: var(--color-primary-bg);
      min-height: 92vh;
      transition: background 0.5s ease-out;
    }
  }

  &.no-left {
    --layout-left-width: 0;
  }

  &.no-right {
    --layout-right-width: 0;
  }

  &.no-bottom {
    --layout-bottom-height: 0;
  }

  &.no-top {
    --layout-top-height: 0;
    >.top {
      overflow: hidden;
    }
  }

  &.One {
    >.content{
      >.content-container {
        max-width: inherit;

        .rz-breadcrumb {
          margin-left: var(--layout-fullpage-padding);
        }
      }
    }
  }
  
  &.Fullwidth {
    >.content{
      >.content-container {
        max-width: ${asRem(1350)};
        padding-right: ${asRem(28)};
        padding-left: ${asRem(28)};
        
      }
    }
  }

  &.Schedule {
    >.content{
      >.content-container {
        max-width: ${asRem(1400)};
        margin: 0 auto;
        padding-right: ${asRem(28)};
        padding-left: ${asRem(28)};
      }
    }
  }

  &.V2 {
    >.content{
      >.content-container {
        max-width: 100rem;
        margin: 0 auto;
        padding-right: ${asRem(28)};
        padding-left: ${asRem(28)};
        --color-primary-bg: var(--color-t2-primary-bg);
        ${'' /* background: var(--color-t2-primary-bg); */}
      }
    }
  }

  &.Two {
    >.content{
      >.content-container {
        padding: ${asRem(20)} ${asRem(28)};
        @media screen and (min-width: ${Breakpoint.md}) {
          padding: var(--layout-nav-height) var(--layout-right-width) 
            var(--layout-bottom-height) var(--layout-left-width);
          max-width: var(--layout-container-width-1);
        }
       
      }
    }
  }


  &.Three {
    >.content{
      margin: 0 var(--layout-fullpage-padding);
      >.content-container {
        --layout-container-width-1: var(--layout-xs-max-width);
        @media screen and (min-width: ${Breakpoint.sm}) {
          --layout-container-width-1: var(--layout-container-width-2);
        }
      }
    }
  }

  &.Four {
    >.content{
      >.content-container {
        --layout-container-width-1: var(--layout-xs-max-width);
        @media screen and (min-width: ${Breakpoint.sm}) {
          --layout-container-width-1: var(--layout-md-max-width);
        }
      }
    }
  }
  &.Seven {
    >.content{
      >.content-container {
        --layout-container-width-1: ${asRem(980)};
        margin-left: ${asRem(30)};
      }
    }
  }
  &.Five {
    .rz-breadcrumb {
      display: none;
    }
    >.content{
      max-width: calc(100% - ${asRem(400)});
      >.content-container {
        --layout-container-width-1: var(--layout-md-max-width);
      }
    }
  }

  &.Six, &.Eight {
    .second-bar {
      display: none;
    }

    >.content{
      margin: 0 var(--layout-fullpage-padding);
      padding-top: ${asRem(58)};
      >.content-container {
        --layout-container-width-1: var(--layout-container-width-3);
        padding-top: ${asRem(40)};
      }
    }
  }

  &.Nine {
    >.content {
      max-width: ${asRem(1120)};
      margin: 0 auto;
      padding-top: ${asRem(110)};
      >.content-container {
        --layout-container-width-1: 100%;
        padding-top: 0;
      }
    }
  }

  &.Ten {
    margin-left: 0;
    margin-right: 0;
    >.content{
      >.content-container {
        --layout-container-width-1: ${asRem(800)};
        margin: 0 auto;
      }
    }
  }

  &.Import {
    >.content{
      >.content-container {
        --layout-container-width-1: ${asRem(1080)};
        margin: 0 auto;
      }
    }
  }


  &.Eight {
    aside {
      .sports-wrapper {
        display: none;
      }

      .account-name-wrapper {
        display: none;
      }
    }

  }

  &.Pricing {
    :has(.not-top-bar) {
      .top {
        .primary {
        display: none;
      }
    }
    }
    :has(.not-border) {
      .top {
        .primary {
          > div {
            background: transparent;
            border: none;
          }
        }
      }
    }
    .second-bar {
      display: none;
    }

    >.content {
      >.content-container {
        --layout-container-width-1: 100%;
        padding: 0;
      }
    }

    .bottom {
      display: none;
    }
  }


  &.app-fullscreen {
    .hide-on-fullscreen {
      display: none;
    }
  }

  &.FantasyEditor {
    >.content{
      >.content-container {
        padding-top: ${asRem(110)};
      }
    }
  }

  &.Cms {
    .top {
      position: fixed;
      left: ${asRem(20)};
      bottom: ${asRem(20)};
      top: ${asRem(20)};
      right: unset;
      z-index: 1000;
      background: none;
      border: none;

      div {
        &.primary {
          left: ${asRem(20)};
          bottom: ${asRem(20)};
          right: unset;
          background: none;
          border: none;
        }
      }

      .secondary {
        left: 0;
        bottom: 0;
        right: unset;
      }
    }
    >.content {
      >.content-container {
        max-width: var(--layout-container-width-1);
        padding-top: ${asRem(40)};
      }

      .rz-breadcrumb {
        display: none;
      }
    }

    &.Three {
      >.content {
        >.content-container {
          max-width: calc(100% - ${asRem(320)});
          margin: 0 0 0 ${asRem(340)};
        }
      }
    }
    ${'' /* &.One {
      >.content {
        >.content-container {
          max-width: calc(100% - ${asRem(66)});
          margin: 0 0 0 ${asRem(88)};
        }
      }
    } */}
    &.Two, &.One {
      >.content {
        >.content-container {
          margin: 0 0 0 ${asRem(320)};
          max-width: 100%;
          padding-top: ${asRem(28)};
          .content-body {
            margin: 0 ${asRem(80)};
            max-width: 100%;

            @media screen and (min-width: ${asRem(1900)}) {
              margin: 0 ${asRem(120)};
            }
          }
        }
      }
    }
  }


`;

export function AppLayout({
  top, left, right, bottom, children, state,
}) {
  const classes = ['app-root'];
  if (state.isFullScreen) classes.push('app-fullscreen');
  if (!top || !state.top || state.isFullScreen) classes.push('no-top');
  if (!left || !state.left || state.isFullScreen) classes.push('no-left');
  if (!right || !state.right || state.isFullScreen) classes.push('no-right');
  if (!bottom || !state.bottom || state.isFullScreen) classes.push('no-bottom');
  if (state.layoutWidth) classes.push(state.layoutWidth);
  if (state.menuLayout) classes.push(state.menuLayout);
  // console.log('Layout is updated');

  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    appContext.toggleTopMenu({ primary: true, secondary: false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (appContext.showSecondaryMenu) {
    classes.push('secondary-on');
  } else {
    classes.push('secondary-off');
  }

  return (
    <div>
      {userContext.loggedIn ? (
        <AppLayoutWrapper className={`${classes.join(' ')} ${userContext.sport}`}>
          <div className="top">{top}</div>
          <div className="content">
            <div className="content-container">
              <div className="content-body">
                {children}
              </div>
            </div>
            {right && <div className="content-right">{right}</div>}
          </div>
          {bottom && <div className="bottom">{bottom}</div>}
        </AppLayoutWrapper>
      ) : (
        <div className="content-body">
          {children}
        </div>
      )}
    </div>
  );
}

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
  top: PropTypes.element.isRequired,
  left: PropTypes.element,
  right: PropTypes.element,
  bottom: PropTypes.element,
  state: PropTypes.object,
};
